.internal-users {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.internal-users-header {
    color: #ffff;
    background-color: var(--main-color);
    font-size: .9rem;
    padding: .5em;
}


.search-users-input-container {
    width: 30%;
    background-color: #fff !important;
    padding: .5em;
    border-radius: 5px;
    margin-left: 1em;
    margin-top: 1em;
    border: 1px solid gray;

}

.search-users-input-container>input {
    width: 100%;
    border: none;
    height: 100%;
    outline-width: 0;
}

.table-users-table-header {
    position: sticky;
}

.internal-users-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 2em;
    height: 100%;
}

.internal-users-table {
    border-collapse: collapse;
    width: 100%;
}

.add-internal-user-btn {
    padding: 12px 25px;
    background-color: var(--main-color);
    color: #fff;
    border-radius: 5px;
}

.edit-internal-user,
.delete-internal-user {
    display: inline-block;
    padding: 12px 23px;
    width: 100%;
    border: none;
    background-color: var(--main-color);
    color: #fff;
}

.delete-internal-user {
    background-color: red;
}


.add-internal-user-form {
    width: 80%;
    margin-left: auto;
    padding: 2.5em 1.5em;
    margin-right: auto;
    background-color: white;

}

.edit-internal-user:hover,
.delete-internal-user:hover {
    transform: scale(.95);
}

.internal-user-inputs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}

.internal-users-buttons-container {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-end;
}

.add-internal-user-btn {
    display: inline-block;
    position: relative;
    border: none;
    border-radius: 2px;
    transition: .3s ease;
    cursor: pointer;
}

.add-internal-user-btn:hover {
    transform: scale(.98);
}


/* .rdt_Table {
    height: 200px;
} */