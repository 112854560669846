.our-products-section {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
}

.our-products-section__header {
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    text-transform: capitalize;
}

.products-container {
    margin-top: 3em;
}

.product {
    padding: 1.5em;
    background-color: #f6f6f6;
    border-radius: 5px;
    margin-bottom: 3em;
}

.product-header {
    display: flex;
    align-items: center;
}

.img-container {
    width: 50px;
    height: 50px;
}

.img-container >img {
    width: 100%;
}

.product-name {
    font-family: 'Roboto',sans-serif;
    margin-left: 10px;
    text-transform: capitalize;
    font-weight: 500;
}


.product-desc {
    margin-top: 2em;
    font-size: .9rem;
    line-height: 25px;
}

.read-more-btn {
    background-color: transparent;
    cursor: pointer;
    border: 0;
    color: var(--main-color);
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: .9rem;
    text-transform: uppercase;
    display: block;
    height: 50px;
    margin-top: 1em;
    transition: .3s ease;
}

.read-more-btn:hover {
    color: gray;
}

@media (min-width: 504px){

    .product {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 820px){

    .products-container {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}