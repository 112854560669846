.sidebar-container {
    width: 100%;
    height: 100%;
    font-family: 'Roboto',sans-serif;
    overflow-y: scroll;
}



.bureau-header-container,
.business-reports-header-container{
    padding: .7em;
    background-color: #0d475c;
    display: flex;
    align-items: center;
}

.bureau-header,
.business-report-header{
    color: white;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;
    margin-left: 10px;
}

.bureau-icon-container,
.business-report-icon-container{
    width: 20px;
    height: 20x;
}

.bureau-icon-container > img,
.business-report-icon-container > img
{
    width: 100%;
}

/* bureau navigation */

.bureau-nav,
.business-reports-nav
{
    padding: 1em;
}

.bureau-nav:disabled{
    background-color: gray;
    color: black;
    opacity: .8;
}

.invoicing-link-container{
    padding: .8em;
}

.systems-admin-link-container  {
    padding: 0 .8em
}

.syt

.systems-admin-link-container {
    margin-top: 0px;
}

.invoicing-link-container {
    border-bottom: 1px solid whitesmoke;
}

.bureau-nav a,
.business-reports-nav a,
.invoicing-link-container > a,
.systems-admin-link-container > a,
.systems-admin-menu > a,
.records-btn{
    display: block;
    transition: .3s ease;
    text-decoration: none;
    color: white;
    text-transform: capitalize;
    margin-bottom: 15px;
    font-size: .8rem
    
}


.activeMenu-btn {
    display: flex !important;
    align-items: center;
}

.systems-admin-expand {
    margin-left: 15px;
}


.records-btn{
    background-color: transparent;
    border: none;
    color: white;
    font-size: .8rem;
    font-family: 'Roboto',sans-serif;
    display: block;
    margin: 20px 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    transition: .3s ease;
}

.bureau-nav a:hover,
.business-reports-nav a:hover,
.systems-admin-link-container > a:hover,
.invoicing-link-container > a:hover,
.systems-admin-menu > a:hover,
.records-btn:hover {
    transform: scale(.95);
}

.active-side-link {
    border-left: 2px solid white;
    padding: .4em 0;
    padding-left: 10px;
    border-bottom: none;
}

.records-menu,
.records-menu-active-accounts,
.systems-admin-menu{
    padding: 1em;
    background-color: #0d475c;
    border-radius: 5px;
    transition: .5s ease;
    display: none;
    width: 0;
}

.systems-admin-menu {
    background-color: transparent;
}

.invoicing-dropdown-menu {
    display: block;
    width: initial;
}

/* .systems-admin-menu{
    position: absolute;
    left: 14%;
    bottom: 0%;
} */

/* .records-menu {
    position: absolute;
    left: 14%;
    top:  35vh;
    z-index: 10;
}
 */
.records-menu-active-accounts {
    position: relative;
    top: -10px;
    padding: 10px;
}

.records-menu a,
.invoicing-dropdown-menu a,
.systems-admin-menu a{
    font-size: .8rem;
    text-decoration: underline;
}

.show-records-menu,
.show-systems-admin-menu{
    display: block;
    width: 100%;
}

.rotate-expand {
    transform: rotateX(180deg);
}

.invoicing-link-container > a,
.systems-admin-link-container > a {
    display: flex;
    align-items: center;
}

.invoicing-dropdown-menu {
    font-size:.8rem;
    padding: 10px;
    position: relative;
    top: -10px;
}

.invoicing-dropdown-menu a {
    color: #fff;
    text-decoration: underline;
    display: block;
    margin-bottom: 8px;
}

.receipt{
    margin-right: 10px;
}

.systems-admin-text {
    font-size: 1rem;
    color: white;
    border-bottom: 1px solid lightgray;

}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #0d475c;
    border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
  }




