.form-container {
    width: 100%;
    background-color: whitesmoke;
    border-radius: 5px;
    border: 1px solid var(--main-color)
}

.icons{
    color : white
}
.miniheadin{
    width: 100%;
    background-color: aqua;
}

.form {
    padding: 1.5em .7em;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
    overflow-y: scroll;
}

.form-header {
    background-color: var(--main-color);
    color: white;
    padding: 1em;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
}

.form-headerhpimain {
    background-color: black;
    color: white;
    padding: 1em;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
}

.formButtons {
    background-color: var(--main-color);
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin-left: 3em;
    cursor: pointer;
    height: 30px;
    width : 20%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.formButtonscloseRecord {
    background-color: red;
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin-left: 3em;
    cursor: pointer;
    height: 30px;
    width : 20%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.formButtons:hover {
    transform: scale(1.025);
    background-color: #062a3d;
}

.formButtons:active{
    background-color: #062a3d;
}


