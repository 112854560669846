.section-pricing {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1.5em;
}

.pricing-nav-container {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
}

.pricing-nav-container button {
    border: none;
    color: black;
    border-radius: 5px;
    transition: .3s ease;
    background-color: white;
    padding: 12px 25px;
}

.active-pricing {
    background-color: var(--main-color) !important;
    color: white !important;
    border-bottom: 1px solid var(--main-color) !important;
}

.pricing-nav-container button:hover {
    background-color: var(--main-color);
    color: white !important;

}

.enquiries-heading {
    background-color: var(--main-color);
    text-transform: capitalize;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    padding: .5em;
    border-radius: 3px;
}

.standard-pricing,
.special-pricing {
    color: #fff;
    background-color: var(--main-color);
    text-transform: capitalize;
    font-size: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: .5em;
    width: max-content;
    border-radius: 3px;

}

.special-pricing {
    margin-top: 2em;
}

.standard-pricing-table,
.special-pricing-table {
    width: 100%;
    border-collapse: collapse;
    font-size: .9rem;
    margin-top: 1em;
}

.standard-pricing-table tr td {
    text-align: center;
}

.add-special-sub-form {
    width: 100%;
    margin-top: 1.5em;
}

.add-special-sub-form__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-special-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
}

.submit-special-pricing,
.add-special-btn-container button {
    color: white;
    border: none;
    border-radius: 5px;
    background-color: var(--main-color);
    padding: 12px 25px;
    display: inline-block;
    margin-top: 1.5em;
    margin-left: 1.5em;
    transition: .3s ease;
}

.submit-special-pricing {
    margin-top: 0;
}

.submit-special-pricing:hover,
.add-special-btn-container button:hover {
    border-radius: 30px;
}