@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@100;300;400;500;700&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  --main-color: #176987;
  font-family: 'Roboto',sans-serif;
}


header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    margin-right: auto;
    margin-left: auto;
    padding: 1.5em 0;
    position: -webkit-sticky;
    position: sticky;
    
}

.logo {
    width: 150px;
}

.homepage-nav {
    position: absolute;
    right: -1.2em;
    width: 150px;
    background-color: var(--main-color);
    height: 100vh;
    border-radius: 5px;
    top: 4em;
    transition: .5s ease;
    padding: 0em;
    overflow: none;
    display: none;
}

.show-homepage-nav {
    padding: 1.5em;
    display: block;
}

.homepage-nav a {
    display: flex;
    align-items: center;
    color:  #fff;
    padding: 15px 0;
    margin-bottom: 1.5em;
    text-decoration: none;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
}

.burger{
    background-color: var(--main-color);
    color: white;
    border: none;
    padding: 5px;
    border-radius: 5px;
}

.navIcon {
    color: white;
    margin-right: 10px;
}

@media (min-width: 775px){

    header {
        height: 12vh;
    }

    .logo-container {
        flex: .3 1;
    }

    .homepage-nav {
        flex: .7 1;
        position: initial;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: auto;
        padding: 0;
        right: 0;
        width: auto;
    }

    .homepage-nav a {
        padding: 0;
        margin: 0;
        color: black;
        font-size: .9rem;
        transition: .3s ease;
    }

    .homepage-nav a:hover {
        background-color: var(--main-color);
        color: #fff;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .burger-container {
        display: none;
    }

    .navIcon {
        color: var(--main-color);
        margin-right: 5px;
    }

    .login-link{
        background-color: var(--main-color);
        padding: 10px 15px !important;
        color: white !important;
        border-radius: 5px;
        padding: 15px 25px;
        margin: 30em;
    }

    .login-link:hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    .login-link > .navIcon {
        color: white;
    }
}

@media (min-width: 1200px){

    header {
        margin-top: 1em;
    }
}

main {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.intro-message-container {
    margin-top: 2em;
}

.intro-message-header {
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.intro-message-header > span {
    display: flex;
    color: var(--main-color);
    font-size: 2.1rem;
    margin: 5px 0;
}

.intro-message-para {
    font-size: .9rem;
    font-family: 'Roboto', sans-serif;
    line-height: 25px;
    margin: 2em 0;
}

.get-started-link {
    padding: 15px 25px;
    display: inline-block;
    text-decoration: none;
    background-color: var(--main-color);
    color: #fff;
    text-transform: capitalize;
    border: none;
    border-radius: 5px;
    transition: .3s ease;
}

.section-illustration {
    display: none;
}

@media (min-width: 779px){

    main {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        'info illustration';
        height: 88vh;
    }

    .section-intro-container {
        grid-area: info;
    }

    .intro-message-container {
        position: relative;
        top: 2.5em;
    }

    .section-illustration {
        display: block;
        grid-area: illustration;
    }

    .illustration-container{
        width: 100%;
        position: relative;
        top: 3em;
    }

    .illustration-container > img {
        width: 100%;
    }

    .get-started-link:hover {
        -webkit-transform: scale(1.025);
                transform: scale(1.025);
    }

}

@media (min-width: 1200px) {

    main {
        grid-template-columns: 1fr 2fr;
        width: 100vw;
        padding: 0 4em;
    }

    .intro-message-container {
        width: 500px;
    }

    .illustration-container > img {
        position: relative;
        right: 2em;
    }

    .intro-message-header {
        font-size: 1.8rem;
    }

    .intro-message-header > span {
        font-size: 2.5rem;
    }
}
.company-history {
    padding: 1.5em;
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    background-color: whitesmoke;
    border-radius: 5px;
    margin-top: 4em;
}

.dun-logo-container,
.fin-logo-container,
.trans-logo-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.trans-logo-container {
    position: relative;
    top: -1.3em;
}

.fin-logo-container {
    position: relative;
    top: 1.5em;
}

.dun-logo {
    width: 100%;
}



.fin-logo {
    width: 50%;
}

.trans-union-logo {
    width: 50%;
}

.dun-year,
.trans-year,
.fin-year{
    text-align: center;
    font-size: 1.9rem;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
}

.dun-year{
    position: relative;
    top: -1.3em;
}

.trans-year {
    position: relative;
    top: -.5em;
}

.fin-year {
    position: relative;
    top: 1.3em;
}

@media (min-width: 590px){

    .dun-logo {
        width: 80%;
    }

    .fin-logo,
    .trans-union-logo {
        width: 40%;
    }
}

@media (min-width: 798px){

    .company-history {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5em 2em;
        margin-top: 2em;
    }

    .company-iteration {
        flex: 1 1;
    }

    .dun-logo {
        width: 100%;
    }

    .fin-logo,
    .trans-union-logo {
         width: 60%;
    }

    .fin-logo {
        position: relative;
        top: .5em;
    }

    .fin-logo-container,
    .trans-logo-container {
        top: 0;
    }

    .trans-logo-container {
        position: relative;
        left: .5em;
        top: -.4em;
    }

    .fin-year {
        top: .4em;
    }

    .trans-year {
        position: relative;
        top: -.4em;
    }

    
}

@media (min-width: 1018px){

    .fin-logo {
        position: relative;
        top: .6rem;
    }

    .fin-year {
        top: .9em;
    }

    .trans-year {
        position: relative;
        top: 0;
    }

    .fin-logo,
    .trans-union-logo {
         width: 50%;
    }
}

@media (min-width: 1200px){

    .company-history {
        padding: .3em 0;
        width: 90vw;
        border-radius: 5px;
    }

    .fin-logo,
    .trans-union-logo{
        position: relative;
    }

    .trans-union-logo {
        top: -.2em;
        width: 45%
    }

    .fin-logo {
        width: 43%;
        top: .2em;
    }

    .dun-year {
        position: relative;
        top: -2em;
    }

    .fin-year {
        top: .9em;
    }

    
}


.about-fincheck-section {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6em;
}

.about-fincheck-section__header {
    font-size: 2rem;
    margin-bottom: 1em;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
}

.about-fincheck-info__para {
    font-size: .9rem;
    line-height: 27px;
    font-family: 'Roboto', sans-serif;
}

.about-fincheck-illustration-container {
    display: none;
}

@media (min-width: 1028px){

    .about-fincheck-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: .3fr 1fr;
        grid-template-areas:
        "header header"
        "info illustration";
    }

    .about-fincheck-info-container {
        grid-area: info;
    }

    .about-fincheck-illustration {
        grid-area: illustration
    }

    .about-fincheck-illustration-container {
        display: block;
    }

    .about-fincheck-illustration-container > img {
        width: 70%;
        position: relative;
        left: 2em;
        top: -3em;
    }

    .about-fincheck-section__header{
        grid-area: header
    }

    .about-fincheck-info {
        width: 400px;
    }

    .about-fincheck-info__para {
        margin-top: 3em;
    }


}

@media (min-width: 1200px){

    .about-fincheck-info {
        width: 450px;
    }

    .about-fincheck-illustration-container > img {
        width: 80%;
        left: 2em;
    }

    .about-fincheck-info__para {
        line-height: 35px;
        position: relative;
        left: 2em;
    }
}
.our-values-container {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
}

.our-values-header {
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;
    margin-bottom: 1em;
}

.values {
   color: var(--main-color);
}

.values > li {
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    margin-bottom: .9em;
    position: relative;
    left: 2em;
}

.values > li span {
    color: black;
}

@media (min-width: 1104px){

    .our-values-container {
        position: relative;
        margin-top: 1em;
    }

    .values {
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style: none;
        position:  relative;
        top: 1em;
    }

    .values li {
        padding: 25px 25px;
        background-color: var(--main-color);
        color: white;
        border-radius: 5px;
        position: relative;
        left: 0;
    }

    .values li span {
        color: white;
    }
}

@media (min-width: 1200px){

    .our-values-container {
        margin-top: -3em;
    }
}
.our-products-section {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
}

.our-products-section__header {
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    text-transform: capitalize;
}

.products-container {
    margin-top: 3em;
}

.product {
    padding: 1.5em;
    background-color: #f6f6f6;
    border-radius: 5px;
    margin-bottom: 3em;
}

.product-header {
    display: flex;
    align-items: center;
}

.img-container {
    width: 50px;
    height: 50px;
}

.img-container >img {
    width: 100%;
}

.product-name {
    font-family: 'Roboto',sans-serif;
    margin-left: 10px;
    text-transform: capitalize;
    font-weight: 500;
}


.product-desc {
    margin-top: 2em;
    font-size: .9rem;
    line-height: 25px;
}

.read-more-btn {
    background-color: transparent;
    cursor: pointer;
    border: 0;
    color: var(--main-color);
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: .9rem;
    text-transform: uppercase;
    display: block;
    height: 50px;
    margin-top: 1em;
    transition: .3s ease;
}

.read-more-btn:hover {
    color: gray;
}

@media (min-width: 504px){

    .product {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 820px){

    .products-container {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}
.faq-section {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.faq-section-header {
    font-size: 2rem;
    font-family: 'Roboto',sans-serif;
    margin-bottom: 1em;
    text-transform: capitalize;
}

.collapsible {
    width: 100%;
    background-color: var(--main-color);
    color: white;
    padding: 10px 10px;
    border: none;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsible:hover {
    background-color: white;
    color: black;
}

.content {
    padding: 1.5em;
    display: none;
    overflow: hidden;
    background-color: #f1f1f1;
    transition: .3s ease;
    line-height: 25px;
    font-size: .9rem;
    font-family: .9rem;
}

.display-content {
    display: block;
}
.reviews-section {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
}

.reviews-section__header {
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;

}

.reviews-container {
    margin-top: 2em;
}

.review {
    padding: 1.5em;
    background-color: #f6f6f6;
    margin-bottom: 1.5em;
    border-radius: 5px;
 
}

.review-header {
    display: flex;
    align-items: center;
}

.review-img-container,
.white-circle{
    display: flex;
    align-items: center;
    width: 70px;
    height: 70px;
    background-color: white;
    border-radius: 50%;
}

.review-img-container > img {
    width: 100%;
}

.reviewer-details {
    margin-left: 10px;
}

.reviewer-details > p {
    font-size: .9rem;
    font-family: 'Roboto', sans-serif;
}


.reviewer-name {
    font-weight: 600;
    margin-bottom: 5px;
}

.review_para {
    margin-top: 1em;
    line-height: 25px;
    font-size: .9rem;
    font-family: 'Roboto', sans-serif;
}

@media (min-width:  450px){

    .review {
        width: 339px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width:  800px){

    .reviews-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
}
.our-contact-section {
    padding: 1.5em;
    background-color: whitesmoke;
    margin-top: 4em;
    font-family: 'Roboto',sans-serif;
}

.our-contact-section__header {
    font-size: 2rem;
    margin: 1em 0;
    text-transform: capitalize;

}

.name-input-container,
.email-input-container,
.message-input-container {
    padding: 1em;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 1em;
}

.message-input-container {
    height: 200px;
}

.name-input-container > input,
.email-input-container > input,
.message-input-container > textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline-width: 0;
}

.message-submit-btn-container {
    display: flex;
    margin-top: 2em;
    justify-content: end;
}

.message-submit-btn {
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;

}

.message-submit-btn:hover {
    -webkit-transform: scale(1.025);
            transform: scale(1.025);
}

.full-details-container {
    margin-top: 3em;
}

.telephone,
.physical-address,
.email {
    margin-bottom: 2em;
}

.physical-address-header,
.telephone-header,
.email-header{
    font-size: 1.5rem;
    text-transform: capitalize;
    margin-bottom: .5em;
    display: flex;
    align-items: center;
}

.physical-address > p,
.telephone > p,
.email > p{
    font-size: .9rem;
}

.location-icon,
.call-icon,
.email-icon{
    background-color: var(--main-color);
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
}

.telephone ul {
    list-style-type: square;
    margin-left: 2em;
    font-size: .9rem;
}

.telephone li {
    margin-bottom: 10px;
    font-weight: 400;
}


@media  (min-width: 1000px){

    .contact-details-container {
        display: flex;
        padding: 0 1em;
        margin-top: 4em;
    }

    .contact-details-container,
    .our-contact-section__header{
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }
    

    .form-container {
        flex: 1 1;
    }

    .full-details-container{
        flex: 1 1;
        margin-top: 0;
        margin-left: 4em;
    }

    
}


footer {
    background-color: rgb(24, 24, 24);
    color: white;
    font-family: 'Roboto', sans-serif;
    padding: 1.5em;
}

.links-container {
    margin-top: 2em;
}

.useful-links,
.important-link {
    margin-bottom: 2em;
}
.links-header-container > h3 {
    text-transform: capitalize;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1em;
}

.useful-links a,
.important-links a {
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
    color: white;
    text-align: center;
    text-transform: capitalize;
}

.useful-links a:hover,
.important-links a:hover {
    color: var(--main-color);
}

.copyrights {
    font-size: .9rem;
    text-align: center;
    margin-top: 3em;
}

.company-logo-container {
    display: none;
}


@media (min-width: 1071px){

    .links-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .company-logo-container > img {
        width: 250px;
    }

    .company-logo-container {
        display: block;
    }

    .useful-links,
    .important-link{
        margin-bottom: 0;
    }
}


.login{
    overflow: hidden;
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/login.692d75ba.jpg);
    background-size: 100% 100%;
    height: 100vh;
    font-family: "Roboto", sans-serif;
}
.login_details{
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    width: 30%;
    background: linear-gradient(rgba(0,0,0.3),rgba(0,0,0,0.3));
    padding: 3em 1.5em;
}
.login_input{
    border: none;
    width: 100%;
    outline-width: 0;
}
.username_password_container{
    width: 100%;
}
.lbl_txt_container{
    padding-bottom: 5px;
    width: 100%;
}
.div_label{
    text-align: left;
    color: antiquewhite;
    width: 25%;
    margin-top: auto;
    margin-bottom: auto;
    height: 20px;
}
.login_input_container{
    width : 100%;
    padding: .4em .5em; 
    border: 1px solid #186b89;
    border-radius: 5px;
    background-color: white;
}

.field_container {
    display: block;
    margin-bottom: 1.5em;
    width:100%
}
.td_input{
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: antiquewhite;
}
.td_label{
    padding-left: 5px;
    padding-right: 5px;
    height: 20px;
}
.top_header{
    width:100%;
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    background-color: #186b89;
    height: 30px;
    align-items: center;
    padding: 2em 1.5em;
    border-radius: 5px;
}

.top_header > h1 {
    color: white;
    font-size: 1.3rem;
}
.btn_login{
    width: 100%;
    background-color: var(--main-color);
    color: white;
    padding: 15px 0;
    border: none;
    border-radius: 5px;
}

.btn_forgot-password {
    background-color: transparent;
    border: none;
    color: white;
    margin-top: 1.5em;
}

.btn_forgot-password:hover {
    background-color: transparent;
    -webkit-transform: scale(1.050);
            transform: scale(1.050);
}
.btn_login:hover{
    -webkit-transform: scale(1.010);
            transform: scale(1.010);
    background-color: var(--main-color);
}
.tbl_entry{
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-bottom: auto;
}
input{
    width:100%;
}
.table_header{
    text-align: center;
}

.details_header > h2 {
    margin-left: auto;
    margin-right: auto;
}

.details_header{
    width: 100%;
    margin-top: 0%;
    background-color: #186b89;
    height: 0.8px;
    align-items: center;
    color: white;
    text-align: center;
}
/* h3{
    color: white;
    margin-right: 10%;
} */
.table_header > h4{
    margin-left: auto;
    margin-right: auto;
}
.top_header > h1{
    color: white
}
.homeicons:hover{
    color: black;
    font-size: 40px;
}
.homeicons{
    margin-right: 10%;
    font-size: 1.3rem ;
    color: white;
    border: none;
    text-decoration: none;

}

.home_link {
    text-decoration: none;
}
.lbl_login{
    width: 100%;
    color: white

}
.img_logo{
    height: 15px;
    width : 15px;
}

body {
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
}
.admin-view-container {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 15% 85%;
    grid-template-rows: 13% 87%;
    grid-template-areas: 
    "logo-section top-navbar"
    "side-navbar view-section";
    overflow: hidden;
    transition: .3s ease;
}

.logo-section {
    grid-area: logo-section;
}

.navbar-section {
    grid-area: side-navbar;
    background-color: var(--main-color);
}

.top-navbar-section {
    grid-area: top-navbar;
    background-color: whitesmoke;
}

.view-section {
    grid-area: view-section;
}

.toggle-sidebar {
    grid-template-columns: 3% 97%;
}

.hide-logo {
    display: none;
}

.center-burger {
    position: relative;
    left: 30%;
}
.logo-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.admin-logo-container > img {
    width: 120px;
    position: relative;
    left: .3em;
}

.admin-burger {
    background-color: var(--main-color);
    margin-right: .5em;
    color: white;
    padding: .1em;
    border-radius: 5px;
    cursor: pointer;
}

.admin-burger:hover {
   -webkit-transform: scale(1.025);
           transform: scale(1.025); 
}

.hide-sidenav {
    display: none;
    width: 0%;
    height: 0;
    overflow: hidden;
    
}
.sidebar-container {
    width: 100%;
    height: 100%;
    font-family: 'Roboto',sans-serif;
    overflow-y: scroll;
}



.bureau-header-container,
.business-reports-header-container{
    padding: .7em;
    background-color: #0d475c;
    display: flex;
    align-items: center;
}

.bureau-header,
.business-report-header{
    color: white;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;
    margin-left: 10px;
}

.bureau-icon-container,
.business-report-icon-container{
    width: 20px;
    height: 20x;
}

.bureau-icon-container > img,
.business-report-icon-container > img
{
    width: 100%;
}

/* bureau navigation */

.bureau-nav,
.business-reports-nav
{
    padding: 1em;
}

.bureau-nav:disabled{
    background-color: gray;
    color: black;
    opacity: .8;
}

.invoicing-link-container{
    padding: .8em;
}

.systems-admin-link-container  {
    padding: 0 .8em
}

.syt

.systems-admin-link-container {
    margin-top: 0px;
}

.invoicing-link-container {
    border-bottom: 1px solid whitesmoke;
}

.bureau-nav a,
.business-reports-nav a,
.invoicing-link-container > a,
.systems-admin-link-container > a,
.systems-admin-menu > a,
.records-btn{
    display: block;
    transition: .3s ease;
    text-decoration: none;
    color: white;
    text-transform: capitalize;
    margin-bottom: 15px;
    font-size: .8rem
    
}


.activeMenu-btn {
    display: flex !important;
    align-items: center;
}

.systems-admin-expand {
    margin-left: 15px;
}


.records-btn{
    background-color: transparent;
    border: none;
    color: white;
    font-size: .8rem;
    font-family: 'Roboto',sans-serif;
    display: block;
    margin: 20px 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    transition: .3s ease;
}

.bureau-nav a:hover,
.business-reports-nav a:hover,
.systems-admin-link-container > a:hover,
.invoicing-link-container > a:hover,
.systems-admin-menu > a:hover,
.records-btn:hover {
    -webkit-transform: scale(.95);
            transform: scale(.95);
}

.active-side-link {
    border-left: 2px solid white;
    padding: .4em 0;
    padding-left: 10px;
    border-bottom: none;
}

.records-menu,
.records-menu-active-accounts,
.systems-admin-menu{
    padding: 1em;
    background-color: #0d475c;
    border-radius: 5px;
    transition: .5s ease;
    display: none;
    width: 0;
}

.systems-admin-menu {
    background-color: transparent;
}

.invoicing-dropdown-menu {
    display: block;
    width: initial;
}

/* .systems-admin-menu{
    position: absolute;
    left: 14%;
    bottom: 0%;
} */

/* .records-menu {
    position: absolute;
    left: 14%;
    top:  35vh;
    z-index: 10;
}
 */
.records-menu-active-accounts {
    position: relative;
    top: -10px;
    padding: 10px;
}

.records-menu a,
.invoicing-dropdown-menu a,
.systems-admin-menu a{
    font-size: .8rem;
    text-decoration: underline;
}

.show-records-menu,
.show-systems-admin-menu{
    display: block;
    width: 100%;
}

.rotate-expand {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
}

.invoicing-link-container > a,
.systems-admin-link-container > a {
    display: flex;
    align-items: center;
}

.invoicing-dropdown-menu {
    font-size:.8rem;
    padding: 10px;
    position: relative;
    top: -10px;
}

.invoicing-dropdown-menu a {
    color: #fff;
    text-decoration: underline;
    display: block;
    margin-bottom: 8px;
}

.receipt{
    margin-right: 10px;
}

.systems-admin-text {
    font-size: 1rem;
    color: white;
    border-bottom: 1px solid lightgray;

}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #0d475c;
    border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
  }





.modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.8);
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow-y: scroll;
    padding: 1.5em;
    display: grid;
    place-items: center;
    transition: .3s ease;
}

.close-modal-icon {
    color: white;
    position: absolute;
    top:1em;
    right: 1.5em;
    cursor: pointer;
    background-color: red;
    border-radius: 5px;
}

.close-modal-icon:hover {
    -webkit-transform: scale(1.025);
            transform: scale(1.025);
    color: red;
    background-color: white
}


.loader-container {
    background-color: white;
    padding: 1.5em;
    height: 200px;
    width: 350px;
    border-radius: 5px;
}

.close-claim-icon {
    background-color: red;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.close-claim-icon:hover {
    background-color: white;
    color: red;
}

.loader-container {
    display: grid;
    place-items: center;
}

.response-message {
    text-align: center;  
} 

.response-message  > p {
    font-size: .9rem;
    margin-top: 1em;
    text-transform: capitalize;
}
.search-individuals,
.search-companies,
.search-claims,
.search-hpi{
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.search-individuals-header,
.search-companies-header,
.search-claims-header,
.search-hpi-header {
    background-color: var(--main-color);
    padding: .8em 1em;
    font-size: .9rem;
    border-radius: 2px;
}


.search-individuals-header > p,
.search-companies-header > p,
.search-hpi-header > p,
.search-claims-header > p{
    font-family: 'Roboto',sans-serif;
    text-transform: capitalize;
    color: white;
}

.filter-records {
    padding: 1em 1.5em;
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-fields {
    display: flex;
    align-items: center;
}

.search-container,
.filter-option-container{
    width: 250px;
}

.filter-option-container {
    margin-left: 2em;
}

.search-container > label,
.filter-option-container > label {
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: .7em;
}

.search-input-container,
.select-container {
    padding: .6em;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 17px;
}

.search-input-container > input,
.select-container > select{
    width: 100%;
    border: none;
    outline-width: 0;
}


.message {
    margin-top: 2em;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: .9rem;
}

.search-container-section {
    width: 100%;
    padding: 0 1.5em;
    padding-top: 15px;
}

.search-container-form {
    display: flex;
    align-items: center;
}

.search-term-input-container,
.search-parameter-input-container {
    width: 25%;
}

.search-term-input-container {
    margin-right: 10px;
}

.search-section-btn-container {
    margin-left: 3%;
}

.search-section-btn-container > button {
    padding: 9px 22px;
    background-color: var(--main-color);
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    transition: .3s ease;
    cursor: pointer;
    color: #fff;
}

.search-section-btn-container > button:hover {
    border-radius: 30px;
}


.filter-icon {
    margin-right: 5px;
    color: var(--main-color);
}

.add-record-btn-container,
.search-btn,
.bulk-enq-btn{
    align-self: flex-end;
}



.add-record-btn,
.search-btn,
.bulk-enq-btn{
    padding: 12px 25px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: 500;
    background-color: var(--main-color);
    transition: .3s  ease;
    color: white;
}

.search-btn,
.bulk-enq-btn {
    transition: .3s ease;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    position: relative;
    left: 2.5em;
}

.bulk-enq-btn {
    left: 3.8em;
}

.add-record-btn:hover,
.search-btn:hover,
.bulk-enq-btn:hover{
    cursor: pointer;
    border-radius: 30px;
}

.add-individual-btn > span {
    display: none;
}


.add-record {
    margin-right: 10px;
    margin-top: -2px;
}

.companies-container,
.search-hpi-container,
.individuals-container,
.claims-container{
    background-color: whitesmoke;
    height: 100%;
    margin-top: 1em;
    overflow-y: scroll;
    font-size: .9rem;
}

.claims-container {
    height: 260px;
}

.individuals-header,
.hpi-header,
.companies-header,
.claims-header,
.company,
.individual,
.claim {
    display: flex;
    justify-content: space-between;
    background-color: rgb(228, 228, 228);
    padding: .3em 1.5em;
}

.individuals-header,
.hpi-header,
.companies-header {
    padding: .4em 1.5em;
}


.individual-header {
    position: fixed;
}

.individual,
.company,
.claim{
    background-color: whitesmoke;
    padding: 0 1.5em;
}


.individual:hover,
.company:hover,
.claim:hover{
    background-color: rgb(228, 228, 228);
    border-radius: 5px;
}


.individuals-header > h4,
.hpi-header > h4,
.claims-header > h4,
.companies-header > h4,
.individual > p,
.company> p,
.claim > p,
.delete-btn-container,
.edit-btn-container,
.close-claim-icon-container{
    text-transform: capitalize;
    border-left: 1px solid gray;
    flex: 1 1;
    height: 100%;
    padding: .5em;
}

.hpi-header > h4 {
    font-size: .7rem !important;
}

.edit-btn-container {
    padding: 0;
}
.claim > p {
    font-family: "Roboto",sans-serif;
}



.checkbox {
    transition: .3s ease;
    font-size: .9rem !important;
}

.checkbox:hover {
    cursor: pointer;
    color: green;
    font-weight: 600;
}

.selected {
    background-color: var(--main-color);
    border-radius: 50%;
    color: white;
}

.progress {
    margin-top: 2em;
    display: flex;
    justify-content: center;
}

.edit-btn,
.delete-btn {
    padding: 10px 25px;
    color: white;
    border: none;
    border-radius: 5px;
    transition: .3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.edit-btn {
    padding: 0;
}


.edit-btn:hover,
.delete-btn:hover {
    border-radius: 30px;
}

.delete-btn {
    background-color: red;
}

.edit-icon,
.delete-icon {
    margin-right: 10px;
}

.searched-entity-details {
    display: flex;
    padding: 0 1.5em;
    justify-content: space-between;
    margin-bottom: 1em;
}


.close-records-btn-container {
    display: flex;
    padding: 0 1.5em;
    justify-content: end;
}

.close-records-btn-container > button{
    background-color: green;
    color: white;
}

.entity-id-reg,
.entity-name{
    display: flex;
}

.entity-name-value,
.entity-id-reg-value{
    margin-left: 1em;
}

.not-found-container {
    width: 300px;
    font-size: 1.1rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    border: 1px solid gray;
    padding: .5em;
}

.not-found-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

.not-found-buttons-container > button {
    color: white;
    font-size: .9rem;
    padding: 8px 16px !important;
    border: none;
    cursor: pointer;
}

.ok-btn {
    background-color: var(--main-color);
}
.progress {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.input-container {
    width: 30%;
    margin-bottom: 2em;
    margin-left: 2.5%;
    height: 70px;

}

.value-container-file{
    padding: .5em;
    border: 1px solid gray;
    border-radius: 5px;
    width: 100%;
    height: 50%;
}

.input-area-container{
    width: 30%;
    margin-bottom: 2em;
    margin-left: 2.5%;
    height: 80px;
}

.input-container > label {
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    display: block;
    margin-bottom: 1em;
    font-size: .9rem;
    font-weight: 500;
}

.slt_temp{
    width : 100%;
    height : 100%
}
.opt_temp{
    width : 100%;
    height : 100%
}

.value-container {
    padding: .5em;
    border: 1px solid gray;
    border-radius: 5px;
    width: 100%;
    height: 50%;
}

.value-container-table {
    border-radius: 5px;
    width: 100%;
    height: 20px;
}

.inputtablecss{
    border: 1px solid gray;
    padding: 0.5em;
    margin: auto;
    height: 100%;
    width: 100%;
}

.value-container > input {
    width: 100%;
    height: 100%;
    border: none;
    outline-width: 0;
    background-color: transparent;
}

.valuearea-container {
    padding: .5em;
    border: 1px solid gray;
    border-radius: 5px;
    height: 80px;
}

.valuearea-container > textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline-width: 0;
    background-color: transparent;
}

.searched-dropdown {
    height: 420px;
    overflow-y: scroll;
    z-index: 100;
    width: 100%;
    position: relative;
    background-color: var(--main-color);

}

.searched-dropdown > ul li {
    color: #fff;
    font-size: .9rem;
    padding: 10px;
    border-bottom: 1px solid gray;
    cursor: pointer;
}

.searched-dropdown > ul li:hover {
    background-color: white;
    color: var(--main-color);
}

.fileuploadercontainer-div{
    border: 2px solid #186b89;

}

.label-required{
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    display: flex !important;
    margin-bottom: .5em !important ;
    font-size: .9rem;
    font-weight: 500;
}

.required-asteric{
    color: red;
}


.form-container {
    width: 100%;
    background-color: whitesmoke;
    border-radius: 5px;
    border: 1px solid var(--main-color)
}

.icons{
    color : white
}
.miniheadin{
    width: 100%;
    background-color: aqua;
}

.form {
    padding: 1.5em .7em;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
    overflow-y: scroll;
}

.form-header {
    background-color: var(--main-color);
    color: white;
    padding: 1em;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
}

.form-headerhpimain {
    background-color: black;
    color: white;
    padding: 1em;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
}

.formButtons {
    background-color: var(--main-color);
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin-left: 3em;
    cursor: pointer;
    height: 30px;
    width : 20%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.formButtonscloseRecord {
    background-color: red;
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin-left: 3em;
    cursor: pointer;
    height: 30px;
    width : 20%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.formButtons:hover {
    -webkit-transform: scale(1.025);
            transform: scale(1.025);
    background-color: #062a3d;
}

.formButtons:active{
    background-color: #062a3d;
}



.divaddcomp {
    width: 80vw
}

.txtPhonenumber {
    display: flex
}

.frm_divinput {
    border: 0.5px solid skyblue;
    width: 80%;
    height: 30px;
}

.executive-form-searchDiv {
    width: 50%;
}

.frmaddcompany_divfinancialdistrict {
    border: 0.5px solid blue;
    padding-top: 50px;
    display: flex;

}

.frmaddcompany_divcompanydetails {
    border: 0.5px solid brown;
    padding-top: 50px;
    display: flex;
}

.frmAddcompanies_divbtn {
    border: 0.5px solid red;
    display: flex;
    padding-top: 50px;
}

.frmaddcompany_divphone {
    border: 0.5px solid green;
    padding-top: 50px;
    display: flex
}

.frmaddcompany_divAddress {
    border: 0.5px solid yellow;
    padding-top: 50px;
    display: flex
}

.frmaddcompany_divlegalStatus {
    border: 0.5px solid orange;
    padding-top: 50px;
    display: flex
}

.frmaddcompany_divtradingStatus {
    border: 0.5px solid brown;
    padding-top: 50px;
    display: flex
}

.frmaddcompany_divonTheWeb {
    border: 0.5px solid violet;
    padding-top: 50px;
    display: flex
}

.secinputcontainer {
    margin: auto;
    width: 98%;
    border: 2px solid #186b89;
}

.secButtons {
    margin: auto;
    padding: 1.5em .7em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
    border: 2px solid #186b89;
    width: 98%;
}

.secinputdeatails {
    padding: 1.5em .7em;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
}

.slt_temp {
    width: 100%;
    height: 100;
    border: none;
}


.sectionHeading {
    background-color: var(--main-color);
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
    width: 100%;
}

.sectionHeadinghpifinancier {
    background-color: red;
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
    width: 100%;
}

.sectionHeadinghpilessee {
    background-color: var(--main-color);
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
    width: 100%;
}

.sectionHeadingdetailshpi{
    background-color: black;
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
    width: 100%;
}
.adverse-uploads-container {
    width: 80vw;
    padding: 1.5em;
    background-color: whitesmoke;
    border-radius: 5px;
}

.search-entity {
    display: flex;
    width: 100%;
    border-bottom: 1px solid lightgray;
    margin-bottom: 1.5em;
    align-items: center;
}

.a-search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-entity-btn{
    padding: 12px 23px;
    background-color: var(--main-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 1.5em;
    height: -webkit-max-content;
    height: max-content;
}


.adverse-uploads-nav {
    padding-bottom: 1em;
    display: flex;
    border-bottom: 1px solid gray;
    justify-content: space-between;
    align-items: center;
}

.single-multiple-btns-container > button,
.add-individual-company-btn-container > button{
    padding: 12px 25px;
    border-radius: 5px;
    background-color: #f3f3f3;
    color: #000;
    text-align: center;
    font-weight: bold;
    border: none;
    transition: .3s ease;
    cursor: pointer;
}

.add-individual-company-btn-container {
    display: flex;
    align-items: center;
}


.add-individual-company-btn-container > button {
    color: white;
    padding: 10px 20px;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
}

.add-individual-btn {
    position: relative;
    left: -13px;
}

.add-individual-company-btn-container > button:hover {
    border-radius: 30px;
}

.single-multiple-btns-container > button:hover{
    background-color: var(--main-color);
    color: #fff;
}

.single-multiple-btns-container > button.active{
    background-color: var(--main-color);
    color: #fff;
}

.adverse-single-form {
    display: flex;
    align-items: center;
    margin-top: 2em;
    flex-wrap: wrap;
    
}

.adverse-single-form > .adverse-single-form {
    border-top: 1px solid gray;
    padding-top: 2em;
}

.id-reg-name {
    width: 30%;
}

.id-reg-name > input {
    width: 100%;
}

.btn-adverse-submit {
    display: block;
    padding: 13px 25px;
    background-color: var(--main-color);
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: .3s ease;
    margin-left: 1.3em;
}

.btn-adverse-submit:hover {
    border-radius: 30px;
}

.multiple-data-type-container {
    margin-top: 2em;
    padding-bottom: 1.5em;
    border-bottom: 1px solid gray;
}

.add-entity-btn {
    background-color: transparent;
    padding: 5px 10px;
    background-color: var(--main-color);
    height: -webkit-max-content;
    height: max-content;
    color: white;
    border: none;
    font-weight: 600;
    margin-left: 10px;
    border-radius: 5px;
    display: none;
    cursor: pointer;
}

.show-add-entity-btn {
    display: flex;
    align-items: center;
}

.add-entity-btn:hover {
    border-radius: 30px;
}

.bulk-forms-container {
    padding: 2em 0;
}
.mainContainer {
    width: 100%;
    background-color: whitesmoke;
    border: 3px solid #062a3d;
    height: auto;
   /*  position: fixed; */
}

.BulkTableData{
    text-align: center;
}

.nodataptag{
    color: red;
    align-self: center;
}

.uploader{
    border :  2px solid #062a3d;
    width : 50%;
    height: 100%;
    padding : 20px
}
.UploadSection{
    padding: 10px;
}

.UploadSecMain{
    padding: 10px;
}

.UploadSecMainButons{
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.previewSection{
    height: 400px;
    width : auto;
    padding: 10px;
    border: 2px solid #186b89;
    overflow-y: scroll;
}
.prevTableBody{
    height: 400px;
}

.uploaderInput{
    height: auto;
    width : auto;
    border: 2px solid#186b89;
}

.buttonDiv{
    height: auto;
    width : auto;
    border: 2px solid#186b89;
    justify-content: space-around;
    padding: 10px;
    display: flex;
}

.UploadButton{
    background-color: #186b89;
    height: 40px;
    text-align: center;
    padding: 10px;
    color:white;
    border:#186b89;
    display: flex;
    justify-content: center;
}

.UploadButtondone{
    background-color: green;
    height: 40px;
    text-align: center;
    padding: 10px;
    color:white;
    border:#186b89;
    display: flex;
    justify-content: center;
}

.UploadButtonerror{
    background-color: red;
    height: 40px;
    text-align: center;
    padding: 10px;
    color:white;
    border:#186b89;
    display: flex;
    justify-content: center;
}

.previewrows{
    width: 100%;
}

.previewTable{    
    width: 100%;
    background-color: whitesmoke;
}

.tablecontainerbulk{
    width: 100%;
    padding-top: 0px;
    height: 100%;
    overflow-y: scroll;
}

.InputForm{
    display: flex ;
    width : 100%;
    height: auto;
    border: 1px solid #186b89;
}
.buttonSection{
    height: 100px;
    width : auto;
    padding: 10px;
}

.tableheader{
    background-color: #186b89;
    color: whitesmoke;
    width: 100%;
    height: 30px;
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
}

.header{
    background-color: #186b89;
    text-align: center;
    color : whitesmoke;
}

.fileuploder{
    height: auto;
    width : auto;
}

.infosec{
    width: 100;
    padding: 10px;
    height: 100%;
    justify-content: center;
}

.info{
    justify-content:center;
    margin: auto;
    height: 20%;
    display: flex;
}

.file{  
    display: flex;  
    justify-content: space-between;
    margin: auto auto auto auto;
    padding-top: 10px;
    height: 30%;
}

.semicolony{
    padding-left: 10px;
    padding-right: 10px;
}

.corrupt{
    color: red;
}

.dataentrypanel{
    width: 100%;
    height:auto;
    border: 2px solid #186b89;
    padding: 10px;
}

.inputdivisionbulknegetive{
    width: 100%;
    display: flex;
}
.top-navbar {
    width: 90%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-nav {
    flex: .4 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-nav__btns-container {
    flex: .3 1;
    display: flex;
    justify-content: flex-end;
}

.top-nav > a,
.top-nav > button {
    text-transform: capitalize;
    margin-left: 1.5em;
    font-family: 'Roboto', sans-serif;
}

.top-nav > a {
    color: var(--main-color);
    text-decoration: underline;
} 

.logout-btn,
.notifications {
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color:red;
    color: white;
    border: none;
    padding: 10px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: .3s ease;
}

.notifications {
    background-color: transparent;
    color: var(--main-color);
    margin-right: .7em;
}

.notifications > span {
    background-color: var(--main-color);
    color: white;
    padding: 5px 7px;
    border-radius: 5px;
}

.logout-btn:hover {
    border-radius: 30px;
}

.top-nav > a:hover {
    font-weight: 550;
}

.user-details {
    flex: .3 1;
}

.username,
.system-level{
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
}

.system-level {
    font-size: .9rem;
    margin-top: 5px;
    font-weight: 500;
}



.div_logo{
    height: 60px;
    width : 30;
    text-align: left;
}

.header_row{
    display: flex;
    justify-content: space-between;
}

.Individual_report{
    border: 5px solid black;
    width : 90%;
    margin: auto auto auto auto;
    padding-top: 10px;
     padding-left: 10px;
    padding-right: 10px;
    transition: .3s ease;
    font-size: .9rem;
}

.full-width {
    width: 100%;
}

.report_header{
    width: 100%;
}

.report_table{
    width : 100%;
    margin-top: 10px;
}

.header_address{
    text-align: left;
    padding-top: 10px;
    margin-left: 30%;
}

.header_contactinfo{
    text-align: left;
    padding-top: 10px;
    border-left: 1px solid black;
}

.header_address,
.header_contactinfo {
    font-size: .9rem;
}

.row_heading{
    background-color: rgb(149, 149, 149);
    width : 100%;
    text-align: center;
}

.img_logo{
    width : 100%;
    height : 100%
}

.moto{
    align-self: flex-start;
    font-size: .9rem;
}

.section_heading{
    width : 100%;
    font-size: 1.1rem;
}

.txt_section_data{
    text-align: center;
}

.text_section_left_align {
    text-align: left;
}

.txt_section_data, .txt_rowheading, .report_table{
    border: 1px solid gray;
    border-collapse: collapse;
}

.txtheader-address,
.moto,
.txtheader-contact {
    font-size: .9rem;
    font-weight: 500;
    text-align: left;
}



.report_footer{
    width: 100%;
    padding-top: 20px;
}

.footer_hr{
    color :black;
    height : 2px;
}

.print-btn {
    padding: 12px 25px;
    display: inline-block;
    margin-top: 2em;
    margin-left: 5%;
    border: none;
    border-radius: 5px;
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
    transition: .3s ease;
}

.print-btn:hover {
    border-radius: 30px;
}
.company-report-logo-container {
    height: 200px;
    width: 300px;
    text-align: left;
}

.company-report-logo-container > img {
    width: 100%;
}

.company-report-header-row{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.company-report{
    border: 5px solid black;
    width : 90%;
    margin: auto auto auto auto;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.full-width {
    width: "100%";
}

.report-header {
    width: 100%;
    display: block;
}

.header-address{
    text-align: left;
    padding-top: 10px;
    margin-left: 30%;
}

.header-address,
.header-contactinfo {
    margin-top: 1em;
}


.txtheader-address,
.moto,
.txtheader-contact {
    font-size: .9rem;
    font-weight: 500;
    text-align: left;
}


.header_contactinfo{
    text-align: left;
    padding-top: 10px;
    border-left: 1px solid black;
}
.biu-report-header {
    width: 100%;
}

.fincheck-logo-container {
    width: 200px;
    margin-right: 15px;
}

.fincheck-logo-container > img {
    width: 100%;
}

.biu_logo_header {
    display: flex;
    align-items: center;
}

.fin-motto {
    font-size: .4rem;
    text-transform: capitalize;
    width: 100%;
}

.biu-intro-header {
    display: flex;
    justify-content: center;
}

.biu-intro-header > h2 {
    text-align: center;
    margin: 1em auto;
    width: 100%;
}



.biu-intro-info  {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    margin: 1em;
    padding: 10px 0;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.rating-container,
.client-name-container {
    justify-self: start;
    display: flex;
    font-size: .9rem;
    font-weight: 500;
}


.rating-container > p:nth-child(1),
.client-name-container > p:nth-child(1){
    margin-right: 15px;
}

.client-name-container {
    margin-left: 30%;
}

.date-of-biu-report {
    padding: 10px 0;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
}

.report-date {
    display: flex;
    justify-content: center;
    font-size: .9rem;
    padding: 10px 0;
}

.report-date > p:nth-child(1){
    margin-right: 15px;
}




.mainEditDiv-Container {
    background-color: whitesmoke;
    padding: 40px;
    height: 100%;
    width: 100%;
}

.editComponent {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
    border: 5px solid #062a3d;
}

.navcontainer {
    border-right: 1px solid #062a3d;
    width: 15%;
    height: 100%;
    overflow-y: scroll;
}

.editnavbutton {
    width: 100%;
    height: 40px;
    color: white;
    background-color: #186b89;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-right: none;
    border-left: none;
}

.editnavbutton:hover {
    width: 100%;
    height: 40px;
    color: #186b89;
    background-color: white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-right: none;
    border-left: none;
}

.editnavbuttonactive {
    width: 100%;
    height: 40px;
    color: #186b89;
    background-color: whitesmoke;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-right: none;
    border-left: none;
}

.nav {
    width: 100%;
    height: 100%;
    background-color: #186b89;
}

.tablecontainer {
    width: 85%;
    align-self: flex-end;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.thaddbuttonbiu {
    background-color: green;
    justify-content: center;
    color: white;
}

.buttonadd {
    background-color: var(--main-color);
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin-left: 3em;
    cursor: pointer;
    height: 30px;
    width: 20%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.loadingdiv {
    height: auto;
    width: 100%;
    padding-left: 50%;
    padding-top: 60px;
}

.loadingBIUtables{
    width: 100%;
    border: 5px solid #186b89;
    height: auto;
    margin: auto;
}

.buttondiv {
    padding: 10px;
}

.buttonadd:hover {
    -webkit-transform: scale(1.025);
            transform: scale(1.025);
    background-color: #062a3d;
}

.editicons {
    color: #186b89;
}

.editicons:hover {
    color: #062a3d;
    border: 1px solid #062a3d;
}

.tabledata {
    border-bottom: 1px dotted #062a3d;
    text-align: center;
    height: 30px;
}

.tablerow {
    border-bottom: 1px dotted #062a3d;
}

.tablerowinvisible {
    display: none;
}

.savebiuicons {
    width: 150px;
    height: 30px;
    border: 1px solid green;
    color: lightgreen;
    background: rgb(0, 100, 0);
}

.savebiuicons:hover {
    border-radius: 10px;
}

.tablediv {
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 2px solid #186b89;
}

.errormsg {
    width: 100%;
    height: 100%;
    color: red;
    text-align: center;
    padding-top: 80px;
}

.divwithtable {
    width: 100%;
    height: 80%;
    overflow-y: scroll;
}

.tableBIUedit {
    width: 100%;
    align-content: center;
    overflow-y: scroll;
}

.tableHead {
    color: whitesmoke;
    background-color: #186b89;
    height: 60px;
}

.tablebody {
    overflow-y: scroll;
}

.tableDivHeaderBIU {
    background-color: #186b89;
    text-align: center;
    color: white;
    width: 100%;
    height: 30px;
}

.Biutablecontainer {
    overflow-y: scroll;
    padding: 20px;
    height: auto;
}

.addBiuicontables {
    font-size: x-large;
}

.EditModalcssbiu {
    padding-bottom:-80px;
    padding-top: 80px;
    height: 100%;
    padding-left: 60px;
    padding-right: 60px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 1;
    overflow-y: scroll;
    transition: 0.5s;
}

.EditModalcssbiuHidden {
    display: none;
}

.closeIconDivBIU {
    padding-left: 50px;
}

.closeIconBiu {
    color: white;
    background-color: red;
}

.BiuConfirmButton{
    background-color: red;
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin: auto;
    cursor: pointer;
    height: 30px;
    width : 20%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.BiuConfirmedButton{
    background-color: green;
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin: auto;
    cursor: pointer;
    height: 30px;
    width : 20%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.ConfirmBiuicon{
    color: white;
}
.searchMainDiv{
    height: 60vh;
    width: 100%;
    border: 2px solid #186b89;
    background: whitesmoke;
    padding-top: 0;

}

.search-select-icon{
    color : #186b89;   
}

.search-select-icon:hover{
    color : #062a3d;   
}

.search-header-indorcompany{
    background: #186b89;
    width: 100%;
    height: 5%;
    color: white;
    padding-left:40% ;
}

.search-text{
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-right: 3%;
    width: 100%;
    height: 30%;
}

.search-button-div{
    border: 1px solid #186b89;
    width: 20%;
    height: 80%;
    padding-top: 30px;
    background: lightgray;
}

.search-search-button{
    display: flex;
    width : 100px;
    background: #186b89;
    color: white;
    border: 1px solid #062a3d;
    margin: auto;
}

.search-search-button:hover{
    display: flex;
    width : 100px;
    background: #062a3d;
    color: white;
    border: 1px solid #062a3d;
    margin: auto;
}

.search-table-div{
    border-top: 1px solid #186b89;
    width: 100%;
    height: 60%;
    overflow-y: scroll;
    background-color: white;
    padding : 10px
}

.search-progress{
    width: 100%;
    height: auto;
    padding-left: 45%;
}

.search-error{
    color: red;
}

.search-table{
    width: 100%;
    background: white;
}

.search-table-header{
    background: #186b89;
    color: white;
}

.search-table-data{
    border-bottom: 1px dashed #062a3d;
    text-align: center;
}

.search-nodata{
    background: white;
    color: black;
    width: 100%;
    height: 100%;
}

.search-norecord{
    color : yellowgreen
}

.search-norecord-add{
    color: blue;
    text-decoration: underline;
}

.search-norecord-add:hover{
    color: purple;
    text-decoration: underline;
}
.edit-report-container {
    width: 100vw;
    height: 100vh;
    background-color: red;
}
.clearance-letter {
  width: 100%;
  height: 100%;
  margin-top: 1.5em;
  padding-left: 1.5em;
  overflow: hidden;
}

@page {
  size: 21cm 29.7cm;
  margin: 2.54cm;
}
p {
  line-height: 115%;
  text-align: left;
  orphans: 2;
  widows: 2;
  margin-bottom: 0.25cm;
  direction: ltr;
  background: transparent;
}
p.western {
  so-language: en-US;
}
h2 {
  line-height: 100%;
  text-align: left;
  orphans: 2;
  widows: 2;
  margin-top: 0cm;
  margin-bottom: 0cm;
  direction: ltr;
  background: transparent;
  page-break-after: avoid;
}
h2.western {
  font-family: "Times New Roman", serif;
  font-size: 12pt;
  so-language: en-US;
  font-style: italic;
  font-weight: bold;
}
h2.cjk {
  font-family: "Times New Roman";
  font-size: 12pt;
  font-style: italic;
  font-weight: bold;
}
h2.ctl {
  font-family: "Times New Roman";
  font-size: 12pt;
  font-style: italic;
  font-weight: bold;
}

.blank-line-top {
  line-height: 100%;
  margin-left: 5.08cm;
  text-indent: 1.27cm;
  margin-bottom: 0cm;
}

.clientsMainDiv {
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
    padding-bottom: 20px;
}

.formButtonsClients {
    background-color: var(--main-color);
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin: auto;
    cursor: pointer;
    height: 30px;
    width : 100%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.clientloadingcircle{
    width: 100%;
    padding-left: 49%;
}

.tablecontainerclientstablescroll{
    overflow-y: scroll;
    height : 90%;
}

.clientSearchbuttontableclient{
    width: 80px;
    height: 30px;
    background-color: #186b89;
    color: white;
}

.inputsearchclientstable{
    width: 30%;
    height: 30px;
}

.searchpanel{
    height: 8%;
    border-bottom: 2px solid #186b89;
}

.clientsarenaheader {
    width: 100%;
    height: 6%;
    background-color: #186b89;
    color: white;
    padding-left: 45%;
    padding-top: 2px;
}

.clientsTableContainer {
    padding: 10px;
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
}

.clientsTable {
    width: 100%;
    background-color: white;
}

.clientstableheader {
    background-color: #186b89;
    color: white;
    height: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.clientstableloading {
    margin: auto;
}

.editiconsclients {
    color: #186b89;
}


.editiconsclients:hover {
    color: #062a3d;
    border: 1px solid #062a3d;
}

.clientstabledata {
    border-bottom: 1px dotted #062a3d;
    text-align: center;
}

.clientstabledataicons {
    border: 1px dotted #062a3d;
    text-align: center;
}

.activeclient:hover {
    border: 1px solid green;
    background-color: white;
}

.suspendedclient:hover {
    border: 1px solid red;
    background-color: white;
}


.clientstabledata-active {
    border: 1px dotted #062a3d;
    text-align: center;
    color: green;
}

.clientstabledata-suspended {
    border: 1px dotted #062a3d;
    text-align: center;
    color: red;
}

.clientstableheaderadd {
    background-color: white;
}

.editiconsclientsdelete {
    color: red;
}

.editiconsclientsdelete:hover {
    color: red;
    border: 1px solid #062a3d;
}

.clientaddheader {
    color: green;
}


.addclientmain {
    padding: 40px;
    display: flex;
    width: 100%;
    height: 100%;
}

.addclientnavigation {
    width: 20%;
    height: 100%;
    border: 2px solid #062a3d;
}

.addclientforms {
    width: 80%;
    height: 100%;
    border: 2px solid #186b89;
    overflow-y: scroll;
    padding: 20px;
    background-color: whitesmoke;
}

.navigationaddclient {
    background-color: #186b89;
    width: 100%;
    height: 100%;
}

.addeditnavbutton {
    color: white;
    height: 30px;
    width: 100%;
    background-color: #186b89;
    border: 1px solid white;
}

.addeditnavbutton:hover {
    color: #062a3d;
    height: 30px;
    width: 100%;
    background-color: white;
    border: 1px solid white;
}

.addeditnavbuttonactive {
    color: #062a3d;
    height: 30px;
    width: 100%;
    background-color: whitesmoke;
    border: 1px solid white;
}

.circularprogressdivclientstanle {
    width: 100%;
    padding-left: 48%;
}

.newuserclient {
    width: 100%;
    height: auto;
    border: 2px solid #062a3d;
    background-color: whitesmoke;
}

.newuserHeader {
    width: 100%;
    height: 40px;
    color: white;
    background-color: #186b89;
    padding-left: 42%;
}

.adduserclientheader {
    margin: auto;
}

.userDetails {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding: 10px;
}

.adduserheadingtext {
    height: auto;
    width: auto;
    margin: auto;
    background-color: #186b89;
}

.detailsuser {
    display: flex;
    width: 100%;
    height: auto;
}

.secButtonsclients {
    margin: auto;
    padding: 1.5em .7em;
    border-top: 2px solid #186b89;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
    width: 98%;
}

.addClientmodalContainer {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 1;
}

.addClientmodalContainerhide {
    visibility: hidden;
}

.closeIcon {
    color: white;
    background-color: red;
}

.closeIcon:hover {
    color: red;
    background-color: white;
}

.editClientContainerModal {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 1;
}

.editClientContainerModalHide {
    visibility: hidden;
}

.editclientmain {
    padding: 40px;
    display: flex;
    width: 100%;
    height: 100%;
}

.loadingcircleclients {
    margin: auto;
    width: 100%;
    padding-left: 48%;
}

.loadingstatusclient {
    margin: auto;
}
.dashboard-container {
    width: 100%;
    height: 100%;
    background: url(/static/media/dash.2a84f054.jpg);
    background-position: center;
    background-size: cover;
    padding: 2em;
}

.bureau-dash-enquries {
    background-color: whitesmoke;
    width: 50%;
    border-radius: 0 0 5px 5px;
    box-shadow: 3px 3px 3px gray;
}

.bureau-dash-enquries>h3 {
    text-transform: capitalize;
    background-color: var(--main-color);
    padding: 1em;
    text-align: center;
    font-size: 1rem;
    color: white;
    border-radius: 5px 5px 0 0;
}

.company-or-individual {
    display: flex;
    padding: .7em 0;
}

.company-or-individual>div {
    flex: 1 1;
    text-align: center;
    text-transform: capitalize;
}

.company-or-individual>div button {
    padding: 13px 25px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: var(--main-color);
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
}

.company-or-individual>div button:hover {
    font-weight: 600;
}

.company-or-individual>div button span {
    margin-left: 5px;
    margin-top: 2px;
}

.company-or-individual>div:nth-child(2),
.company-or-individual>div:nth-child(3) {
    border-left: 1px solid gray;
}

.back-pic {
    width: 100%;
    height: 100%;
}
.report-checking {
    background-color: white;
    padding: 2em;
    border-radius: 5px;
}

.report-checking > p {
    font-size: .9rem;
}

.internal-external-enquiry {
    padding: .3em;
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
}

.internal,
.external {
    padding: 15px 25px;
    border: none;
    border-radius: 5px;
    background-color: var(--main-color);
    color: white;
    transition: .3s ease;
}

.internal {
    background-color: green;
}

.external {
    background-color: red;
}

.internal:hover,
.external:hover {
    border-radius: 30px;
}

.requester-container {
    margin-top: 1.5em;
    height: 0;
    overflow: hidden;
    transition: .3s ease;
    border-top: 1px solid gray;
    padding-top: 1em;
}

.show-requester-container {
    height: -webkit-max-content;
    height: max-content;
}
.requester-input-container,
.client-name-input-container,
.branch-input-container {
    padding: .5em;
    border: 1px solid gray;
    border-radius: 5px;
}

.client-name-input-container,
.requester-input-container,
.branch-input-container {
    margin-bottom: 1em;
}


.requester-input-container > input,
.client-name-input-container > input,
.branch-input-container > input {
    border: none;
    outline-width: 0;
}

.go-to-report  {
    display: inline-block;
    padding: 13px 25px;
    background-color: var(--main-color);
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 1.5em;
}

.go-to-report:disabled {
    background-color: gray;
    color: black;
}

.users-dropdown,
.branches-dropdown,
.company-clients-dropdown {
    margin-top: 2px;
    background-color: var(--main-color);
    border-radius: 5px;
    height: 0;
    overflow-y: hidden;
    transition: .3s ease;
}

.show-users-dropdown,
.show-branches-dropdown{
    height: 120px;
    overflow-y: scroll;
}

.users-dropdown ul li,
.branches-dropdown ul li,
.company-clients-dropdown ul li {
    padding: 1em;
    border-bottom: 1px solid lightgray;
    font-size: .9rem;
    color: white;

}

.users-dropdown ul li:hover,
.branches-dropdown ul li:hover,
.company-clients-dropdown ul li:hover {
    background-color: white;
    color: var(--main-color);
}


.edit-company-forms-container,
.edit-individual-forms-container {
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: .1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 
    "navigation form";
    border-radius: 5px;
    position: relative;
    top: -7em;
    left: -3em;
}

.navigation {
    background-color: var(--main-color);
    grid-area: navigation;
    height: 100vh;
    position: fixed;
    top: 0%;
    overflow-y: scroll;
    width: 12.3%;
}

.form-display-area {
    position: relative;
    left: 4.2%;
    top: 10%;
    grid-area: form;
    width: 90%;
}

.navigation > button {
    display: inline-block;
    padding: 15px 25px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    transition: .3s ease;
    font-size: .6rem; 
    width: 100%;
    text-align: left;
}

.navigation > button:hover,
button.active-edit-company-form-btn {
    background-color: white;
    color: black;
}
.reports-search-header {
    padding: .8em .5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.month-end {
    padding: .5em;
    border: 1px solid black;
}

.search-report-input-cont {
    border: 1px solid black;
    width: 250px;
    padding:.5em;
}

.search-report-input-cont input {
    width: 100%;
    border: none;
    outline-width: 0;
}

.reports-timeline-container {
    padding: 1.5em
}

.month-button {
    padding: 10px 23px;
    color: #fff;
    display: inline-block;
    margin-bottom: 1em;
    border-radius: 5px;
    border: none;
    background-color: var(--main-color);
}


.internal-biu-dash-view {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2em;
}

.bui-internal-table-container {
    height: calc(100% - 100px);
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 3px 3px 3px 3px gray;
}

.report-heading-div {
    padding: 10px 0;
}

.bui-internal-reports-reqs {
    position: relative;
    background-color: whitesmoke;
    width: 100%;
    border-radius: 5px;
    border-collapse: collapse;
    border-spacing: 10px 10px;
}

.bui-internal-reports__data-headings>td {
    background-color: gray;
}


.bui-internal-reports__heading {
    background-color: var(--main-color);
    color: white;
}



.report-heading-div>h4 {
    position: relative;
    left: 1em;
}

.bui-internal-reports-data-headings td>b {
    padding: 0 10px
}

.remove-icon {
    color: white;
    position: relative;
    left: 3px;
}

.remove-icon-container {
    background-color: var(--main-color);
}

.add-new-request-btn {
    padding: 10px 20px;
    background-color: var(--main-color);
    color: white;
    position: absolute;
    right: 3em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 2px;
    transition: .3s ease;
    cursor: pointer;

}

.add-new-request {
    background-color: red;
}

.add-new-request-btn:hover {
    border-radius: 30px;
}


.view-BIU-btn,
.edit-information {
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    color: white;
    padding: 10px 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease;
    cursor: pointer;
}

.view-BIU-btn:hover,
.edit-information:hover {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}
.add-biu-request {
    width: 70vw;
    background-color: #fff;
    padding: 1.5em;
    border-radius: 5px;
}

.enquiry-form {
    display: flex;
    align-items: center;
}

.type-of-inq {
    display: flex;
    margin-bottom: 1em;
    padding-bottom: 20px;
    border-bottom: 2px solid gray;
}

.company-radio,
.individual-radio {
    display: flex;
}

.company-radio > p,
.individual-radio > p {
    text-transform: capitalize;
    margin-right: 3px;
}

.company-radio {
    margin-right: 15px;
}

.add,
.close-the-add-req {
    padding: 13px 22px;
    background-color: var(--main-color);
    display: inline-block;
    margin-bottom: 2em;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: .3s ease;
}

.close-the-add-req {
    margin-top: 2em;
}

.add:hover
.close-the-add-req:hover {
    border-radius: 30px;
}
.section-pricing {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1.5em;
}

.pricing-nav-container {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
}

.pricing-nav-container button {
    border: none;
    color: black;
    border-radius: 5px;
    transition: .3s ease;
    background-color: white;
    padding: 12px 25px;
}

.active-pricing {
    background-color: var(--main-color) !important;
    color: white !important;
    border-bottom: 1px solid var(--main-color) !important;
}

.pricing-nav-container button:hover {
    background-color: var(--main-color);
    color: white !important;

}

.enquiries-heading {
    background-color: var(--main-color);
    text-transform: capitalize;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    padding: .5em;
    border-radius: 3px;
}

.standard-pricing,
.special-pricing {
    color: #fff;
    background-color: var(--main-color);
    text-transform: capitalize;
    font-size: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: .5em;
    width: -webkit-max-content;
    width: max-content;
    border-radius: 3px;

}

.special-pricing {
    margin-top: 2em;
}

.standard-pricing-table,
.special-pricing-table {
    width: 100%;
    border-collapse: collapse;
    font-size: .9rem;
    margin-top: 1em;
}

.standard-pricing-table tr td {
    text-align: center;
}

.add-special-sub-form {
    width: 100%;
    margin-top: 1.5em;
}

.add-special-sub-form__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-special-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
}

.submit-special-pricing,
.add-special-btn-container button {
    color: white;
    border: none;
    border-radius: 5px;
    background-color: var(--main-color);
    padding: 12px 25px;
    display: inline-block;
    margin-top: 1.5em;
    margin-left: 1.5em;
    transition: .3s ease;
}

.submit-special-pricing {
    margin-top: 0;
}

.submit-special-pricing:hover,
.add-special-btn-container button:hover {
    border-radius: 30px;
}
.client-biu-dash-view {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2em;
}
.targets-container {
    width: 100%;
}

.targets-header {
    background-color: var(--main-color);
    color: #fff;
    font-size: .9rem;
    padding: 1em;
}

.qty-table {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    border-collapse: collapse;
    margin-top: 2em;
}

.qty-table tr th {
    text-transform: capitalize;
}

.qty-table tr td {
    text-align: center;
}

.edit-icon {
    background-color: var(--main-color);
    cursor: pointer;
}

.edit-target-inputs,
.add-target-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-target-section {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    padding: .5em;
    background-color: whitesmoke;
    border-radius: 5px;
    margin-top: 3em;
}
.edit-targets-submit {
    padding: 12px 25px;
    color: #fff;
    background-color: var(--main-color);
    border-radius: 5px;
    display: inline-block;
    margin: .5em 0;
}


.edit-target-inputs {

    margin-top: 1em;
    padding: .5em;
    background-color: whitesmoke;
}

.add-target-btn {
    padding: 12px 24px;
    color: #fff;
    background-color: var(--main-color);
    border: none;
    border-radius: 5px;
    position: absolute;
    right: 12.5%;

}

.account-periods-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em;
}

.acc-year--{
    text-transform: capitalize;
    font-size: .9em;
}

.acc-date {
    border: 1px solid black;
    padding: .5em;
}


.internal-users {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.internal-users-header {
    color: #ffff;
    background-color: var(--main-color);
    font-size: .9rem;
    padding: .5em;
}


.search-users-input-container {
    width: 30%;
    background-color: #fff !important;
    padding: .5em;
    border-radius: 5px;
    margin-left: 1em;
    margin-top: 1em;
    border: 1px solid gray;

}

.search-users-input-container>input {
    width: 100%;
    border: none;
    height: 100%;
    outline-width: 0;
}

.table-users-table-header {
    position: -webkit-sticky;
    position: sticky;
}

.internal-users-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 2em;
    height: 100%;
}

.internal-users-table {
    border-collapse: collapse;
    width: 100%;
}

.add-internal-user-btn {
    padding: 12px 25px;
    background-color: var(--main-color);
    color: #fff;
    border-radius: 5px;
}

.edit-internal-user,
.delete-internal-user {
    display: inline-block;
    padding: 12px 23px;
    width: 100%;
    border: none;
    background-color: var(--main-color);
    color: #fff;
}

.delete-internal-user {
    background-color: red;
}


.add-internal-user-form {
    width: 80%;
    margin-left: auto;
    padding: 2.5em 1.5em;
    margin-right: auto;
    background-color: white;

}

.edit-internal-user:hover,
.delete-internal-user:hover {
    -webkit-transform: scale(.95);
            transform: scale(.95);
}

.internal-user-inputs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}

.internal-users-buttons-container {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-end;
}

.add-internal-user-btn {
    display: inline-block;
    position: relative;
    border: none;
    border-radius: 2px;
    transition: .3s ease;
    cursor: pointer;
}

.add-internal-user-btn:hover {
    -webkit-transform: scale(.98);
            transform: scale(.98);
}


/* .rdt_Table {
    height: 200px;
} */
.company-settings {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 2em;
}

.company-settings h3 {
    background-color: var(--main-color);
    color: #fff;
    font-size: .9rem;
    padding: 15px;
}

.submit-settings-btn {
    padding: 12px 25px;
    color: #fff;
    background-color: var(--main-color);
    border: none;
    border-radius: 5px;
    transition: .3s ease;
    width: -webkit-max-content;
    width: max-content;
    margin-left: 1.5em;
}

.submit-settings-btn:hover {
    border-radius: 30px;
}

.settings-form {
    width: 90%;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    
}
.settings-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
#Hpi_mainPage__YLAq0 {
    margin: 65px 10px 10px 270px;
  }
  
  .Hpi_page__1Ilc1 {
    font-size: 9pt;
    padding:5px;
  }
  
  .Hpi_invoiceHeader__Xmq1g {
    font-size: 14pt;
    font-weight: normal;
    color: #585D61;
  }
  
  .Hpi_inputCriteria__3REuS {
    border: 1px solid #b0d2a3;
    background-color: #258db3;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    font-weight: bold;
    padding-left: 20px;
    margin-bottom: 15px;
    border-radius: 3px;
  }
  
  .Hpi_selectionBoxRow__2aIrJ {
    padding: 2px 0;
  }
  .Hpi_selectionLabel__1nzty {
    display: inline-block;
    width: 130px;
  }
  
  .Hpi_selectionBoxSelect__2ynvc {
    width: 394px;
    padding: 3px;
  }
  
  
  
  .Hpi_sectionContainer__2H1V4 {
    display: block;
    margin-bottom: 20px;
    border: 1px solid #cacaca;
    padding: 10px;
    border-radius: 3px;
  }
  
  .Hpi_cent__3QD5P {
    font-size: 14pt;
    font-weight: normal;
    color: #585D61;
  }
  
  .Hpi_floatTheadWrapper__bpghu table{
    border-collapse: collapse;
  }
  
  .Hpi_floatTheadWrapper__bpghu table{
  
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: gray;
  
  }
  
  
  
  .Hpi_floatTheadWrapper__bpghu tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    
  }
  
  .Hpi_floatTheadWrapper__bpghu tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    
  }
  
  .Hpi_floatTheadWrapper__bpghu th {
    text-align: left;
    color: #39547d;
    padding: 3px 0 3px 1px;
    font-size: 13px;
    font-weight: bold;
    border-collapse: collapse;
    border-bottom: 1px solid #cad5e5;
    min-height: 28px;
  }
  
  .Hpi_floatTheadWrapper__bpghu td, .Hpi_floatTheadWrapper__bpghu th {
    padding: 18px;
    border: 1px solid #c0c0c0;
    /* border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0; */
  
  }
  
  element.Hpi_style__3ai27 {
    border-collapse: collapse;
    border: 0px none rgb(128, 128, 128);
    display: table;
    margin: 0px;
    table-layout: auto;
    width: 1185px;
  }
  
  .Hpi_sectionTable__WelVn {
    border-collapse: collapse;
    margin-bottom: 40px;
  }
  
  .Hpi_thead__2c080 {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    background-color: #EEEEEE;
  }
  
  .Hpi_tr__1K8Wm {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  
  .Hpi_td__1DKd4{
    padding-right: 1px;
    padding-left: 1px;
    height: 28px;
    white-space: nowrap;
  }
  
  .Hpi_tftr__26dEn {
    padding: 5px;
    /* background-color: #176987; */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  
  /* .tbody{
    height: 30px;
    overflow-y: scroll;
  } */
  
  .Hpi_contentDiv__1s-QX {
    /* margin-bottom: 8px; */
    margin:8px;
  
    padding:4px;
    /* width: 500px; */
    height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align:justify;
  }
  
  .Hpi_submitBtn__1YMnH{
    background-color: #0d475c;
    border: none;
    color: #fff;
    border-radius: 5px;
    transition: .3s ease;
    padding: 12px 25px;
  
  }
#Invoice_mainPage__2KADB {
  margin: 65px 10px 10px 270px;
}

.Invoice_page__Q00ZY {
  font-size: 9pt;
  padding:5px;
}

.Invoice_invoiceHeader__2TMkQ {
  font-size: 14pt;
  font-weight: normal;
  color: #585D61;
}

.Invoice_inputCriteria__2pdaC {
  border: 1px solid #b0d2a3;
  background-color: #258db3;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 15px;
  border-radius: 3px;
}

.Invoice_selectionBoxRow__3zgRS {
  padding: 2px 0;
}
.Invoice_selectionLabel__3Qs78 {
  display: inline-block;
  width: 130px;
}

.Invoice_selectionBoxSelect__3VkiK {
  width: 394px;
  padding: 3px;
}



.Invoice_sectionContainer__25UhS {
  display: block;
  margin-bottom: 20px;
  border: 1px solid #cacaca;
  padding: 10px;
  border-radius: 3px;
}

.Invoice_cent__2-nuy {
  font-size: 14pt;
  font-weight: normal;
  color: #585D61;
}

.Invoice_floatTheadWrapper__3vrgy table{
  border-collapse: collapse;
}

.Invoice_floatTheadWrapper__3vrgy table{

  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;

}



.Invoice_floatTheadWrapper__3vrgy tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  
}

.Invoice_floatTheadWrapper__3vrgy tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  
}

.Invoice_floatTheadWrapper__3vrgy th {
  text-align: left;
  color: #39547d;
  padding: 3px 0 3px 1px;
  font-size: 13px;
  font-weight: bold;
  border-collapse: collapse;
  border-bottom: 1px solid #cad5e5;
  min-height: 28px;
}

.Invoice_floatTheadWrapper__3vrgy td, .Invoice_floatTheadWrapper__3vrgy th {
  padding: 18px;
  border: 1px solid #c0c0c0;
  /* border-left: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0; */

}

element.Invoice_style__2FoIr {
  border-collapse: collapse;
  border: 0px none rgb(128, 128, 128);
  display: table;
  margin: 0px;
  table-layout: auto;
  width: 1185px;
}

.Invoice_sectionTable__3qIMB {
  border-collapse: collapse;
  margin-bottom: 40px;
}

.Invoice_thead__5pHoY {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  background-color: #EEEEEE;
}

.Invoice_tr__2V4nd {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.Invoice_td__G2Tm-{
  padding-right: 1px;
  padding-left: 1px;
  height: 28px;
  white-space: nowrap;
}

.Invoice_tftr__3LboJ {
  padding: 5px;
  /* background-color: #176987; */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

/* .tbody{
  height: 30px;
  overflow-y: scroll;
} */

.Invoice_contentDiv__Quot3 {
  /* margin-bottom: 8px; */
  margin:8px;

  padding:4px;
  /* width: 500px; */
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
}

.Invoice_submitBtn__o6aBf{
  background-color: #0d475c;
  border: none;
  color: #fff;
  border-radius: 5px;
  transition: .3s ease;
  padding: 12px 25px;

}
#FiscalTaxInvoice_mainPage__dYcKY {
  margin: 65px 10px 10px 270px;
}



.FiscalTaxInvoice_page__2Crrw {
  font-size: 9pt;
  padding:5px;
}
.FiscalTaxInvoice_printBtn__4Wlbn{
  display: none;
}

.FiscalTaxInvoice_invoiceHeader__pKWlL {
  font-size: 14pt;
  font-weight: normal;
  color: #585D61;
}

.FiscalTaxInvoice_inputCriteria__2OYHU {
  border: 1px solid #b0d2a3;
  background-color: #258db3;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 15px;
  border-radius: 3px;
}

.FiscalTaxInvoice_selectionBoxRow__2uZxp {
  padding: 2px 0;
}
.FiscalTaxInvoice_selectionLabel__1uIRg {
  display: inline-block;
  width: 130px;
}

.FiscalTaxInvoice_selectionBoxSelect__23h9M {
  width: 394px;
  padding: 3px;
}



.FiscalTaxInvoice_sectionContainer__iHQOK {
  display: block;
  margin-bottom: 20px;
  border: 1px solid #cacaca;
  padding: 10px;
  border-radius: 3px;
}

.FiscalTaxInvoice_cent__1z-nx {
  font-size: 14pt;
  font-weight: normal;
  color: #585D61;
}

.FiscalTaxInvoice_removePrintBtn__1w9l1{
  display: none;
}

.FiscalTaxInvoice_floatTheadWrapper__2lwt_ table{
  border-collapse: collapse;
}

.FiscalTaxInvoice_floatTheadWrapper__2lwt_ table{

  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;

}

#FiscalTaxInvoice_tfaTable__3WNtC tr:nth-child(even){background-color: #f2f2f2;}


.FiscalTaxInvoice_floatTheadWrapper__2lwt_ tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  
}

.FiscalTaxInvoice_floatTheadWrapper__2lwt_ tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  
}

.FiscalTaxInvoice_floatTheadWrapper__2lwt_ th {
  text-align: left;
  color: #39547d;
  padding: 3px 0 3px 1px;
  font-size: 13px;
  font-weight: bold;
  border-collapse: collapse;
  border-bottom: 1px solid #cad5e5;
  min-height: 28px;
}

.FiscalTaxInvoice_floatTheadWrapper__2lwt_ td, .FiscalTaxInvoice_floatTheadWrapper__2lwt_ th {
  padding: 18px;
  border: 1px solid #c0c0c0;
  /* border-left: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0; */

}

element.FiscalTaxInvoice_style__US9sH {
  border-collapse: collapse;
  border: 0px none rgb(128, 128, 128);
  display: table;
  margin: 0px;
  table-layout: auto;
  width: 1185px;
}

.FiscalTaxInvoice_sectionTable__1NS3Z {
  border-collapse: collapse;
  margin-bottom: 40px;
}

.FiscalTaxInvoice_thead__NTN-s {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  background-color: #EEEEEE;
}

.FiscalTaxInvoice_tr__1rNs1 {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.FiscalTaxInvoice_td__1l6mT{
  padding-right: 1px;
  padding-left: 1px;
  height: 28px;
  white-space: nowrap;
}

.FiscalTaxInvoice_tftr__1TdFS {
  padding: 5px;
  /* background-color: #176987; */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

/* .tbody{
  height: 30px;
  overflow-y: scroll;
} */

.FiscalTaxInvoice_contentDiv__49Bkr {
  /* margin-bottom: 8px; */
  margin:8px;

  padding:4px;
  /* width: 500px; */
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
}

.FiscalTaxInvoice_submitBtn__3_8xu{
  background-color: #0d475c;
  border: none;
  color: #fff;
  border-radius: 5px;
  transition: .3s ease;
  padding: 12px 25px;

}
.ModalLoading_TFcontainer__5XT3d {
    width: 80%;
    margin: 50px auto;
  }
  
  .ModalLoading_TFbutton__2XJrd {
    background: #2196f3;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
  }
  
  .ModalLoading_TFbutton__2XJrd:hover {
    background: #0b7dda;
  }
  
  /* Style the modal */
  .ModalLoading_TFmodal__1LuhB {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal Content */
  .ModalLoading_TFmodalContent__NnQ1s {
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fefefe;
  }
  
  .ModalLoading_TFmodalText__2jKUq {
    margin-left: 14px;
    font-size: 24px;
  }
  
  /* Create the circle loader with pure CSS */
  .ModalLoading_TFloader__1mMBi {
    width: 50px;
    height: 50px;
    border: 8px solid #ddd;
    border-top: 8px solid #2196f3;
    border-bottom: 8px solid #2196f3;
    border-radius: 50%;
    -webkit-animation: ModalLoading_spin__3LnDV 2s linear infinite;
    animation: ModalLoading_spin__3LnDV 2s linear infinite;
  }
  
  @-webkit-keyframes ModalLoading_spin__3LnDV {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes ModalLoading_spin__3LnDV {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
#InvoiceReport_mainPage__3GEgI {
    margin: 65px 10px 10px 270px;
  }
  
  .InvoiceReport_page__1apdV {
    font-size: 9pt;
    padding:5px;
  }
  .InvoiceReport_printBtn__2_sGo{
    display: none;
  }
  
  .InvoiceReport_invoiceHeader__1Ip4K {
    font-size: 14pt;
    font-weight: normal;
    color: #585D61;
  }
  
  .InvoiceReport_inputCriteria__1X22t {
    border: 1px solid #b0d2a3;
    background-color: #258db3;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    font-weight: bold;
    padding-left: 20px;
    margin-bottom: 15px;
    border-radius: 3px;
  }
  
  .InvoiceReport_selectionBoxRow__2kzQa {
    padding: 2px 0;
  }
  .InvoiceReport_selectionLabel__2YGMu {
    display: inline-block;
    width: 130px;
  }
  
  .InvoiceReport_selectionBoxSelect__2b8Am {
    width: 394px;
    padding: 3px;
  }
  
  
  
  .InvoiceReport_sectionContainer__1Ipyx {
    display: block;
    margin-bottom: 20px;
    border: 1px solid #cacaca;
    padding: 10px;
    border-radius: 3px;
  }
  
  .InvoiceReport_cent__KD2d_ {
    font-size: 14pt;
    font-weight: normal;
    color: #585D61;
  }
  
  .InvoiceReport_removePrintBtn__18w_c{
    display: none;
  }

  .InvoiceReport_floatTheadWrapper__NZxZF table{
    border-collapse: collapse;
  }
  
  .InvoiceReport_floatTheadWrapper__NZxZF table{
  
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: gray;
  
  }
  
  #InvoiceReport_tfaTable__2tEhG tr:nth-child(even){background-color: #f2f2f2;}
  

  .InvoiceReport_floatTheadWrapper__NZxZF tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    
  }
  
  .InvoiceReport_floatTheadWrapper__NZxZF tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    
  }
  
  .InvoiceReport_floatTheadWrapper__NZxZF th {
    text-align: left;
    color: #39547d;
    padding: 3px 0 3px 1px;
    font-size: 13px;
    font-weight: bold;
    border-collapse: collapse;
    border-bottom: 1px solid #cad5e5;
    min-height: 28px;
  }
  
  .InvoiceReport_floatTheadWrapper__NZxZF td, .InvoiceReport_floatTheadWrapper__NZxZF th {
    padding: 18px;
    border: 1px solid #c0c0c0;
    /* border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0; */
  
  }
  
  element.InvoiceReport_style__3fZxF {
    border-collapse: collapse;
    border: 0px none rgb(128, 128, 128);
    display: table;
    margin: 0px;
    table-layout: auto;
    width: 1185px;
  }
  
  .InvoiceReport_sectionTable__3xo_3 {
    border-collapse: collapse;
    margin-bottom: 40px;
  }
  
  .InvoiceReport_thead__3qcFj {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    background-color: #EEEEEE;
  }
  
  .InvoiceReport_tr__1tOVR {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  
  .InvoiceReport_td__16gEg{
    padding-right: 1px;
    padding-left: 1px;
    height: 28px;
    white-space: nowrap;
  }
  
  .InvoiceReport_tftr__JzYsU {
    padding: 5px;
    /* background-color: #176987; */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  
  /* .tbody{
    height: 30px;
    overflow-y: scroll;
  } */
  
  .InvoiceReport_contentDiv__3PYvs {
    /* margin-bottom: 8px; */
    margin:8px;
  
    padding:4px;
    /* width: 500px; */
    height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align:justify;
  }
  
  .InvoiceReport_submitBtn__3ObKp{
    background-color: #0d475c;
    border: none;
    color: #fff;
    border-radius: 5px;
    transition: .3s ease;
    padding: 12px 25px;
  
  }
  .InvoiceReport_InvoiceBtn__1_CFY{
    background-color: hsl(0, 85%, 51%);
    border: none;
    color: #fff;
    border-radius: 5px;
    transition: .3s ease;
    padding: 12px 25px;
  
  }
