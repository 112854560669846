.report-checking {
    background-color: white;
    padding: 2em;
    border-radius: 5px;
}

.report-checking > p {
    font-size: .9rem;
}

.internal-external-enquiry {
    padding: .3em;
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
}

.internal,
.external {
    padding: 15px 25px;
    border: none;
    border-radius: 5px;
    background-color: var(--main-color);
    color: white;
    transition: .3s ease;
}

.internal {
    background-color: green;
}

.external {
    background-color: red;
}

.internal:hover,
.external:hover {
    border-radius: 30px;
}

.requester-container {
    margin-top: 1.5em;
    height: 0;
    overflow: hidden;
    transition: .3s ease;
    border-top: 1px solid gray;
    padding-top: 1em;
}

.show-requester-container {
    height: max-content;
}
.requester-input-container,
.client-name-input-container,
.branch-input-container {
    padding: .5em;
    border: 1px solid gray;
    border-radius: 5px;
}

.client-name-input-container,
.requester-input-container,
.branch-input-container {
    margin-bottom: 1em;
}


.requester-input-container > input,
.client-name-input-container > input,
.branch-input-container > input {
    border: none;
    outline-width: 0;
}

.go-to-report  {
    display: inline-block;
    padding: 13px 25px;
    background-color: var(--main-color);
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 1.5em;
}

.go-to-report:disabled {
    background-color: gray;
    color: black;
}

.users-dropdown,
.branches-dropdown,
.company-clients-dropdown {
    margin-top: 2px;
    background-color: var(--main-color);
    border-radius: 5px;
    height: 0;
    overflow-y: hidden;
    transition: .3s ease;
}

.show-users-dropdown,
.show-branches-dropdown{
    height: 120px;
    overflow-y: scroll;
}

.users-dropdown ul li,
.branches-dropdown ul li,
.company-clients-dropdown ul li {
    padding: 1em;
    border-bottom: 1px solid lightgray;
    font-size: .9rem;
    color: white;

}

.users-dropdown ul li:hover,
.branches-dropdown ul li:hover,
.company-clients-dropdown ul li:hover {
    background-color: white;
    color: var(--main-color);
}

