.targets-container {
    width: 100%;
}

.targets-header {
    background-color: var(--main-color);
    color: #fff;
    font-size: .9rem;
    padding: 1em;
}

.qty-table {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    border-collapse: collapse;
    margin-top: 2em;
}

.qty-table tr th {
    text-transform: capitalize;
}

.qty-table tr td {
    text-align: center;
}

.edit-icon {
    background-color: var(--main-color);
    cursor: pointer;
}

.edit-target-inputs,
.add-target-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-target-section {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    padding: .5em;
    background-color: whitesmoke;
    border-radius: 5px;
    margin-top: 3em;
}
.edit-targets-submit {
    padding: 12px 25px;
    color: #fff;
    background-color: var(--main-color);
    border-radius: 5px;
    display: inline-block;
    margin: .5em 0;
}


.edit-target-inputs {

    margin-top: 1em;
    padding: .5em;
    background-color: whitesmoke;
}

.add-target-btn {
    padding: 12px 24px;
    color: #fff;
    background-color: var(--main-color);
    border: none;
    border-radius: 5px;
    position: absolute;
    right: 12.5%;

}

.account-periods-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em;
}

.acc-year--{
    text-transform: capitalize;
    font-size: .9em;
}

.acc-date {
    border: 1px solid black;
    padding: .5em;
}

