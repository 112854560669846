.edit-company-forms-container,
.edit-individual-forms-container {
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: .1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 
    "navigation form";
    border-radius: 5px;
    position: relative;
    top: -7em;
    left: -3em;
}

.navigation {
    background-color: var(--main-color);
    grid-area: navigation;
    height: 100vh;
    position: fixed;
    top: 0%;
    overflow-y: scroll;
    width: 12.3%;
}

.form-display-area {
    position: relative;
    left: 4.2%;
    top: 10%;
    grid-area: form;
    width: 90%;
}

.navigation > button {
    display: inline-block;
    padding: 15px 25px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    transition: .3s ease;
    font-size: .6rem; 
    width: 100%;
    text-align: left;
}

.navigation > button:hover,
button.active-edit-company-form-btn {
    background-color: white;
    color: black;
}