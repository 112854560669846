.div_logo{
    height: 60px;
    width : 30;
    text-align: left;
}

.header_row{
    display: flex;
    justify-content: space-between;
}

.Individual_report{
    border: 5px solid black;
    width : 90%;
    margin: auto auto auto auto;
    padding-top: 10px;
     padding-left: 10px;
    padding-right: 10px;
    transition: .3s ease;
    font-size: .9rem;
}

.full-width {
    width: 100%;
}

.report_header{
    width: 100%;
}

.report_table{
    width : 100%;
    margin-top: 10px;
}

.header_address{
    text-align: left;
    padding-top: 10px;
    margin-left: 30%;
}

.header_contactinfo{
    text-align: left;
    padding-top: 10px;
    border-left: 1px solid black;
}

.header_address,
.header_contactinfo {
    font-size: .9rem;
}

.row_heading{
    background-color: rgb(149, 149, 149);
    width : 100%;
    text-align: center;
}

.img_logo{
    width : 100%;
    height : 100%
}

.moto{
    align-self: flex-start;
    font-size: .9rem;
}

.section_heading{
    width : 100%;
    font-size: 1.1rem;
}

.txt_section_data{
    text-align: center;
}

.text_section_left_align {
    text-align: left;
}

.txt_section_data, .txt_rowheading, .report_table{
    border: 1px solid gray;
    border-collapse: collapse;
}

.txtheader-address,
.moto,
.txtheader-contact {
    font-size: .9rem;
    font-weight: 500;
    text-align: left;
}



.report_footer{
    width: 100%;
    padding-top: 20px;
}

.footer_hr{
    color :black;
    height : 2px;
}

.print-btn {
    padding: 12px 25px;
    display: inline-block;
    margin-top: 2em;
    margin-left: 5%;
    border: none;
    border-radius: 5px;
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
    transition: .3s ease;
}

.print-btn:hover {
    border-radius: 30px;
}