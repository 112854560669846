.reports-search-header {
    padding: .8em .5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.month-end {
    padding: .5em;
    border: 1px solid black;
}

.search-report-input-cont {
    border: 1px solid black;
    width: 250px;
    padding:.5em;
}

.search-report-input-cont input {
    width: 100%;
    border: none;
    outline-width: 0;
}

.reports-timeline-container {
    padding: 1.5em
}

.month-button {
    padding: 10px 23px;
    color: #fff;
    display: inline-block;
    margin-bottom: 1em;
    border-radius: 5px;
    border: none;
    background-color: var(--main-color);
}

