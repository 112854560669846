.search-individuals,
.search-companies,
.search-claims,
.search-hpi{
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.search-individuals-header,
.search-companies-header,
.search-claims-header,
.search-hpi-header {
    background-color: var(--main-color);
    padding: .8em 1em;
    font-size: .9rem;
    border-radius: 2px;
}


.search-individuals-header > p,
.search-companies-header > p,
.search-hpi-header > p,
.search-claims-header > p{
    font-family: 'Roboto',sans-serif;
    text-transform: capitalize;
    color: white;
}

.filter-records {
    padding: 1em 1.5em;
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-fields {
    display: flex;
    align-items: center;
}

.search-container,
.filter-option-container{
    width: 250px;
}

.filter-option-container {
    margin-left: 2em;
}

.search-container > label,
.filter-option-container > label {
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: .7em;
}

.search-input-container,
.select-container {
    padding: .6em;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 17px;
}

.search-input-container > input,
.select-container > select{
    width: 100%;
    border: none;
    outline-width: 0;
}


.message {
    margin-top: 2em;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: .9rem;
}

.search-container-section {
    width: 100%;
    padding: 0 1.5em;
    padding-top: 15px;
}

.search-container-form {
    display: flex;
    align-items: center;
}

.search-term-input-container,
.search-parameter-input-container {
    width: 25%;
}

.search-term-input-container {
    margin-right: 10px;
}

.search-section-btn-container {
    margin-left: 3%;
}

.search-section-btn-container > button {
    padding: 9px 22px;
    background-color: var(--main-color);
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    transition: .3s ease;
    cursor: pointer;
    color: #fff;
}

.search-section-btn-container > button:hover {
    border-radius: 30px;
}


.filter-icon {
    margin-right: 5px;
    color: var(--main-color);
}

.add-record-btn-container,
.search-btn,
.bulk-enq-btn{
    align-self: flex-end;
}



.add-record-btn,
.search-btn,
.bulk-enq-btn{
    padding: 12px 25px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: 500;
    background-color: var(--main-color);
    transition: .3s  ease;
    color: white;
}

.search-btn,
.bulk-enq-btn {
    transition: .3s ease;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    position: relative;
    left: 2.5em;
}

.bulk-enq-btn {
    left: 3.8em;
}

.add-record-btn:hover,
.search-btn:hover,
.bulk-enq-btn:hover{
    cursor: pointer;
    border-radius: 30px;
}

.add-individual-btn > span {
    display: none;
}


.add-record {
    margin-right: 10px;
    margin-top: -2px;
}

.companies-container,
.search-hpi-container,
.individuals-container,
.claims-container{
    background-color: whitesmoke;
    height: 100%;
    margin-top: 1em;
    overflow-y: scroll;
    font-size: .9rem;
}

.claims-container {
    height: 260px;
}

.individuals-header,
.hpi-header,
.companies-header,
.claims-header,
.company,
.individual,
.claim {
    display: flex;
    justify-content: space-between;
    background-color: rgb(228, 228, 228);
    padding: .3em 1.5em;
}

.individuals-header,
.hpi-header,
.companies-header {
    padding: .4em 1.5em;
}


.individual-header {
    position: fixed;
}

.individual,
.company,
.claim{
    background-color: whitesmoke;
    padding: 0 1.5em;
}


.individual:hover,
.company:hover,
.claim:hover{
    background-color: rgb(228, 228, 228);
    border-radius: 5px;
}


.individuals-header > h4,
.hpi-header > h4,
.claims-header > h4,
.companies-header > h4,
.individual > p,
.company> p,
.claim > p,
.delete-btn-container,
.edit-btn-container,
.close-claim-icon-container{
    text-transform: capitalize;
    border-left: 1px solid gray;
    flex: 1;
    height: 100%;
    padding: .5em;
}

.hpi-header > h4 {
    font-size: .7rem !important;
}

.edit-btn-container {
    padding: 0;
}
.claim > p {
    font-family: "Roboto",sans-serif;
}



.checkbox {
    transition: .3s ease;
    font-size: .9rem !important;
}

.checkbox:hover {
    cursor: pointer;
    color: green;
    font-weight: 600;
}

.selected {
    background-color: var(--main-color);
    border-radius: 50%;
    color: white;
}

.progress {
    margin-top: 2em;
    display: flex;
    justify-content: center;
}

.edit-btn,
.delete-btn {
    padding: 10px 25px;
    color: white;
    border: none;
    border-radius: 5px;
    transition: .3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.edit-btn {
    padding: 0;
}


.edit-btn:hover,
.delete-btn:hover {
    border-radius: 30px;
}

.delete-btn {
    background-color: red;
}

.edit-icon,
.delete-icon {
    margin-right: 10px;
}

.searched-entity-details {
    display: flex;
    padding: 0 1.5em;
    justify-content: space-between;
    margin-bottom: 1em;
}


.close-records-btn-container {
    display: flex;
    padding: 0 1.5em;
    justify-content: end;
}

.close-records-btn-container > button{
    background-color: green;
    color: white;
}

.entity-id-reg,
.entity-name{
    display: flex;
}

.entity-name-value,
.entity-id-reg-value{
    margin-left: 1em;
}

.not-found-container {
    width: 300px;
    font-size: 1.1rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    border: 1px solid gray;
    padding: .5em;
}

.not-found-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

.not-found-buttons-container > button {
    color: white;
    font-size: .9rem;
    padding: 8px 16px !important;
    border: none;
    cursor: pointer;
}

.ok-btn {
    background-color: var(--main-color);
}