.company-history {
    padding: 1.5em;
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    background-color: whitesmoke;
    border-radius: 5px;
    margin-top: 4em;
}

.dun-logo-container,
.fin-logo-container,
.trans-logo-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.trans-logo-container {
    position: relative;
    top: -1.3em;
}

.fin-logo-container {
    position: relative;
    top: 1.5em;
}

.dun-logo {
    width: 100%;
}



.fin-logo {
    width: 50%;
}

.trans-union-logo {
    width: 50%;
}

.dun-year,
.trans-year,
.fin-year{
    text-align: center;
    font-size: 1.9rem;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
}

.dun-year{
    position: relative;
    top: -1.3em;
}

.trans-year {
    position: relative;
    top: -.5em;
}

.fin-year {
    position: relative;
    top: 1.3em;
}

@media (min-width: 590px){

    .dun-logo {
        width: 80%;
    }

    .fin-logo,
    .trans-union-logo {
        width: 40%;
    }
}

@media (min-width: 798px){

    .company-history {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5em 2em;
        margin-top: 2em;
    }

    .company-iteration {
        flex: 1;
    }

    .dun-logo {
        width: 100%;
    }

    .fin-logo,
    .trans-union-logo {
         width: 60%;
    }

    .fin-logo {
        position: relative;
        top: .5em;
    }

    .fin-logo-container,
    .trans-logo-container {
        top: 0;
    }

    .trans-logo-container {
        position: relative;
        left: .5em;
        top: -.4em;
    }

    .fin-year {
        top: .4em;
    }

    .trans-year {
        position: relative;
        top: -.4em;
    }

    
}

@media (min-width: 1018px){

    .fin-logo {
        position: relative;
        top: .6rem;
    }

    .fin-year {
        top: .9em;
    }

    .trans-year {
        position: relative;
        top: 0;
    }

    .fin-logo,
    .trans-union-logo {
         width: 50%;
    }
}

@media (min-width: 1200px){

    .company-history {
        padding: .3em 0;
        width: 90vw;
        border-radius: 5px;
    }

    .fin-logo,
    .trans-union-logo{
        position: relative;
    }

    .trans-union-logo {
        top: -.2em;
        width: 45%
    }

    .fin-logo {
        width: 43%;
        top: .2em;
    }

    .dun-year {
        position: relative;
        top: -2em;
    }

    .fin-year {
        top: .9em;
    }

    
}

