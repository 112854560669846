.faq-section {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.faq-section-header {
    font-size: 2rem;
    font-family: 'Roboto',sans-serif;
    margin-bottom: 1em;
    text-transform: capitalize;
}

.collapsible {
    width: 100%;
    background-color: var(--main-color);
    color: white;
    padding: 10px 10px;
    border: none;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsible:hover {
    background-color: white;
    color: black;
}

.content {
    padding: 1.5em;
    display: none;
    overflow: hidden;
    background-color: #f1f1f1;
    transition: .3s ease;
    line-height: 25px;
    font-size: .9rem;
    font-family: .9rem;
}

.display-content {
    display: block;
}