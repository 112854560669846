.company-settings {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 2em;
}

.company-settings h3 {
    background-color: var(--main-color);
    color: #fff;
    font-size: .9rem;
    padding: 15px;
}

.submit-settings-btn {
    padding: 12px 25px;
    color: #fff;
    background-color: var(--main-color);
    border: none;
    border-radius: 5px;
    transition: .3s ease;
    width: max-content;
    margin-left: 1.5em;
}

.submit-settings-btn:hover {
    border-radius: 30px;
}

.settings-form {
    width: 90%;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    
}
.settings-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}