footer {
    background-color: rgb(24, 24, 24);
    color: white;
    font-family: 'Roboto', sans-serif;
    padding: 1.5em;
}

.links-container {
    margin-top: 2em;
}

.useful-links,
.important-link {
    margin-bottom: 2em;
}
.links-header-container > h3 {
    text-transform: capitalize;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1em;
}

.useful-links a,
.important-links a {
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
    color: white;
    text-align: center;
    text-transform: capitalize;
}

.useful-links a:hover,
.important-links a:hover {
    color: var(--main-color);
}

.copyrights {
    font-size: .9rem;
    text-align: center;
    margin-top: 3em;
}

.company-logo-container {
    display: none;
}


@media (min-width: 1071px){

    .links-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .company-logo-container > img {
        width: 250px;
    }

    .company-logo-container {
        display: block;
    }

    .useful-links,
    .important-link{
        margin-bottom: 0;
    }
}

