.adverse-uploads-container {
    width: 80vw;
    padding: 1.5em;
    background-color: whitesmoke;
    border-radius: 5px;
}

.search-entity {
    display: flex;
    width: 100%;
    border-bottom: 1px solid lightgray;
    margin-bottom: 1.5em;
    align-items: center;
}

.a-search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-entity-btn{
    padding: 12px 23px;
    background-color: var(--main-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 1.5em;
    height: max-content;
}


.adverse-uploads-nav {
    padding-bottom: 1em;
    display: flex;
    border-bottom: 1px solid gray;
    justify-content: space-between;
    align-items: center;
}

.single-multiple-btns-container > button,
.add-individual-company-btn-container > button{
    padding: 12px 25px;
    border-radius: 5px;
    background-color: #f3f3f3;
    color: #000;
    text-align: center;
    font-weight: bold;
    border: none;
    transition: .3s ease;
    cursor: pointer;
}

.add-individual-company-btn-container {
    display: flex;
    align-items: center;
}


.add-individual-company-btn-container > button {
    color: white;
    padding: 10px 20px;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
}

.add-individual-btn {
    position: relative;
    left: -13px;
}

.add-individual-company-btn-container > button:hover {
    border-radius: 30px;
}

.single-multiple-btns-container > button:hover{
    background-color: var(--main-color);
    color: #fff;
}

.single-multiple-btns-container > button.active{
    background-color: var(--main-color);
    color: #fff;
}

.adverse-single-form {
    display: flex;
    align-items: center;
    margin-top: 2em;
    flex-wrap: wrap;
    
}

.adverse-single-form > .adverse-single-form {
    border-top: 1px solid gray;
    padding-top: 2em;
}

.id-reg-name {
    width: 30%;
}

.id-reg-name > input {
    width: 100%;
}

.btn-adverse-submit {
    display: block;
    padding: 13px 25px;
    background-color: var(--main-color);
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: .3s ease;
    margin-left: 1.3em;
}

.btn-adverse-submit:hover {
    border-radius: 30px;
}

.multiple-data-type-container {
    margin-top: 2em;
    padding-bottom: 1.5em;
    border-bottom: 1px solid gray;
}

.add-entity-btn {
    background-color: transparent;
    padding: 5px 10px;
    background-color: var(--main-color);
    height: max-content;
    color: white;
    border: none;
    font-weight: 600;
    margin-left: 10px;
    border-radius: 5px;
    display: none;
    cursor: pointer;
}

.show-add-entity-btn {
    display: flex;
    align-items: center;
}

.add-entity-btn:hover {
    border-radius: 30px;
}

.bulk-forms-container {
    padding: 2em 0;
}