.add-biu-request {
    width: 70vw;
    background-color: #fff;
    padding: 1.5em;
    border-radius: 5px;
}

.enquiry-form {
    display: flex;
    align-items: center;
}

.type-of-inq {
    display: flex;
    margin-bottom: 1em;
    padding-bottom: 20px;
    border-bottom: 2px solid gray;
}

.company-radio,
.individual-radio {
    display: flex;
}

.company-radio > p,
.individual-radio > p {
    text-transform: capitalize;
    margin-right: 3px;
}

.company-radio {
    margin-right: 15px;
}

.add,
.close-the-add-req {
    padding: 13px 22px;
    background-color: var(--main-color);
    display: inline-block;
    margin-bottom: 2em;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: .3s ease;
}

.close-the-add-req {
    margin-top: 2em;
}

.add:hover
.close-the-add-req:hover {
    border-radius: 30px;
}