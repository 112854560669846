.dashboard-container {
    width: 100%;
    height: 100%;
    background: url("./../backgrounds/dash.jpg");
    background-position: center;
    background-size: cover;
    padding: 2em;
}

.bureau-dash-enquries {
    background-color: whitesmoke;
    width: 50%;
    border-radius: 0 0 5px 5px;
    box-shadow: 3px 3px 3px gray;
}

.bureau-dash-enquries>h3 {
    text-transform: capitalize;
    background-color: var(--main-color);
    padding: 1em;
    text-align: center;
    font-size: 1rem;
    color: white;
    border-radius: 5px 5px 0 0;
}

.company-or-individual {
    display: flex;
    padding: .7em 0;
}

.company-or-individual>div {
    flex: 1;
    text-align: center;
    text-transform: capitalize;
}

.company-or-individual>div button {
    padding: 13px 25px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: var(--main-color);
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
}

.company-or-individual>div button:hover {
    font-weight: 600;
}

.company-or-individual>div button span {
    margin-left: 5px;
    margin-top: 2px;
}

.company-or-individual>div:nth-child(2),
.company-or-individual>div:nth-child(3) {
    border-left: 1px solid gray;
}

.back-pic {
    width: 100%;
    height: 100%;
}