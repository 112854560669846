.our-contact-section {
    padding: 1.5em;
    background-color: whitesmoke;
    margin-top: 4em;
    font-family: 'Roboto',sans-serif;
}

.our-contact-section__header {
    font-size: 2rem;
    margin: 1em 0;
    text-transform: capitalize;

}

.name-input-container,
.email-input-container,
.message-input-container {
    padding: 1em;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 1em;
}

.message-input-container {
    height: 200px;
}

.name-input-container > input,
.email-input-container > input,
.message-input-container > textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline-width: 0;
}

.message-submit-btn-container {
    display: flex;
    margin-top: 2em;
    justify-content: end;
}

.message-submit-btn {
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;

}

.message-submit-btn:hover {
    transform: scale(1.025);
}

.full-details-container {
    margin-top: 3em;
}

.telephone,
.physical-address,
.email {
    margin-bottom: 2em;
}

.physical-address-header,
.telephone-header,
.email-header{
    font-size: 1.5rem;
    text-transform: capitalize;
    margin-bottom: .5em;
    display: flex;
    align-items: center;
}

.physical-address > p,
.telephone > p,
.email > p{
    font-size: .9rem;
}

.location-icon,
.call-icon,
.email-icon{
    background-color: var(--main-color);
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
}

.telephone ul {
    list-style-type: square;
    margin-left: 2em;
    font-size: .9rem;
}

.telephone li {
    margin-bottom: 10px;
    font-weight: 400;
}


@media  (min-width: 1000px){

    .contact-details-container {
        display: flex;
        padding: 0 1em;
        margin-top: 4em;
    }

    .contact-details-container,
    .our-contact-section__header{
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }
    

    .form-container {
        flex: 1;
    }

    .full-details-container{
        flex: 1;
        margin-top: 0;
        margin-left: 4em;
    }

    
}

