main {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.intro-message-container {
    margin-top: 2em;
}

.intro-message-header {
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.intro-message-header > span {
    display: flex;
    color: var(--main-color);
    font-size: 2.1rem;
    margin: 5px 0;
}

.intro-message-para {
    font-size: .9rem;
    font-family: 'Roboto', sans-serif;
    line-height: 25px;
    margin: 2em 0;
}

.get-started-link {
    padding: 15px 25px;
    display: inline-block;
    text-decoration: none;
    background-color: var(--main-color);
    color: #fff;
    text-transform: capitalize;
    border: none;
    border-radius: 5px;
    transition: .3s ease;
}

.section-illustration {
    display: none;
}

@media (min-width: 779px){

    main {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        'info illustration';
        height: 88vh;
    }

    .section-intro-container {
        grid-area: info;
    }

    .intro-message-container {
        position: relative;
        top: 2.5em;
    }

    .section-illustration {
        display: block;
        grid-area: illustration;
    }

    .illustration-container{
        width: 100%;
        position: relative;
        top: 3em;
    }

    .illustration-container > img {
        width: 100%;
    }

    .get-started-link:hover {
        transform: scale(1.025);
    }

}

@media (min-width: 1200px) {

    main {
        grid-template-columns: 1fr 2fr;
        width: 100vw;
        padding: 0 4em;
    }

    .intro-message-container {
        width: 500px;
    }

    .illustration-container > img {
        position: relative;
        right: 2em;
    }

    .intro-message-header {
        font-size: 1.8rem;
    }

    .intro-message-header > span {
        font-size: 2.5rem;
    }
}