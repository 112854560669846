.clearance-letter {
  width: 100%;
  height: 100%;
  margin-top: 1.5em;
  padding-left: 1.5em;
  overflow: hidden;
}

@page {
  size: 21cm 29.7cm;
  margin: 2.54cm;
}
p {
  line-height: 115%;
  text-align: left;
  orphans: 2;
  widows: 2;
  margin-bottom: 0.25cm;
  direction: ltr;
  background: transparent;
}
p.western {
  so-language: en-US;
}
h2 {
  line-height: 100%;
  text-align: left;
  orphans: 2;
  widows: 2;
  margin-top: 0cm;
  margin-bottom: 0cm;
  direction: ltr;
  background: transparent;
  page-break-after: avoid;
}
h2.western {
  font-family: "Times New Roman", serif;
  font-size: 12pt;
  so-language: en-US;
  font-style: italic;
  font-weight: bold;
}
h2.cjk {
  font-family: "Times New Roman";
  font-size: 12pt;
  font-style: italic;
  font-weight: bold;
}
h2.ctl {
  font-family: "Times New Roman";
  font-size: 12pt;
  font-style: italic;
  font-weight: bold;
}

.blank-line-top {
  line-height: 100%;
  margin-left: 5.08cm;
  text-indent: 1.27cm;
  margin-bottom: 0cm;
}
