.searchMainDiv{
    height: 60vh;
    width: 100%;
    border: 2px solid #186b89;
    background: whitesmoke;
    padding-top: 0;

}

.search-select-icon{
    color : #186b89;   
}

.search-select-icon:hover{
    color : #062a3d;   
}

.search-header-indorcompany{
    background: #186b89;
    width: 100%;
    height: 5%;
    color: white;
    padding-left:40% ;
}

.search-text{
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-right: 3%;
    width: 100%;
    height: 30%;
}

.search-button-div{
    border: 1px solid #186b89;
    width: 20%;
    height: 80%;
    padding-top: 30px;
    background: lightgray;
}

.search-search-button{
    display: flex;
    width : 100px;
    background: #186b89;
    color: white;
    border: 1px solid #062a3d;
    margin: auto;
}

.search-search-button:hover{
    display: flex;
    width : 100px;
    background: #062a3d;
    color: white;
    border: 1px solid #062a3d;
    margin: auto;
}

.search-table-div{
    border-top: 1px solid #186b89;
    width: 100%;
    height: 60%;
    overflow-y: scroll;
    background-color: white;
    padding : 10px
}

.search-progress{
    width: 100%;
    height: auto;
    padding-left: 45%;
}

.search-error{
    color: red;
}

.search-table{
    width: 100%;
    background: white;
}

.search-table-header{
    background: #186b89;
    color: white;
}

.search-table-data{
    border-bottom: 1px dashed #062a3d;
    text-align: center;
}

.search-nodata{
    background: white;
    color: black;
    width: 100%;
    height: 100%;
}

.search-norecord{
    color : yellowgreen
}

.search-norecord-add{
    color: blue;
    text-decoration: underline;
}

.search-norecord-add:hover{
    color: purple;
    text-decoration: underline;
}