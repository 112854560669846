.internal-biu-dash-view {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2em;
}

.bui-internal-table-container {
    height: calc(100% - 100px);
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 3px 3px 3px 3px gray;
}

.report-heading-div {
    padding: 10px 0;
}

.bui-internal-reports-reqs {
    position: relative;
    background-color: whitesmoke;
    width: 100%;
    border-radius: 5px;
    border-collapse: collapse;
    border-spacing: 10px 10px;
}

.bui-internal-reports__data-headings>td {
    background-color: gray;
}


.bui-internal-reports__heading {
    background-color: var(--main-color);
    color: white;
}



.report-heading-div>h4 {
    position: relative;
    left: 1em;
}

.bui-internal-reports-data-headings td>b {
    padding: 0 10px
}

.remove-icon {
    color: white;
    position: relative;
    left: 3px;
}

.remove-icon-container {
    background-color: var(--main-color);
}

.add-new-request-btn {
    padding: 10px 20px;
    background-color: var(--main-color);
    color: white;
    position: absolute;
    right: 3em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 2px;
    transition: .3s ease;
    cursor: pointer;

}

.add-new-request {
    background-color: red;
}

.add-new-request-btn:hover {
    border-radius: 30px;
}


.view-BIU-btn,
.edit-information {
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    color: white;
    padding: 10px 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease;
    cursor: pointer;
}

.view-BIU-btn:hover,
.edit-information:hover {
    transform: scale(0.95);
}