.mainEditDiv-Container {
    background-color: whitesmoke;
    padding: 40px;
    height: 100%;
    width: 100%;
}

.editComponent {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
    border: 5px solid #062a3d;
}

.navcontainer {
    border-right: 1px solid #062a3d;
    width: 15%;
    height: 100%;
    overflow-y: scroll;
}

.editnavbutton {
    width: 100%;
    height: 40px;
    color: white;
    background-color: #186b89;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-right: none;
    border-left: none;
}

.editnavbutton:hover {
    width: 100%;
    height: 40px;
    color: #186b89;
    background-color: white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-right: none;
    border-left: none;
}

.editnavbuttonactive {
    width: 100%;
    height: 40px;
    color: #186b89;
    background-color: whitesmoke;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-right: none;
    border-left: none;
}

.nav {
    width: 100%;
    height: 100%;
    background-color: #186b89;
}

.tablecontainer {
    width: 85%;
    align-self: flex-end;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.thaddbuttonbiu {
    background-color: green;
    justify-content: center;
    color: white;
}

.buttonadd {
    background-color: var(--main-color);
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin-left: 3em;
    cursor: pointer;
    height: 30px;
    width: 20%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.loadingdiv {
    height: auto;
    width: 100%;
    padding-left: 50%;
    padding-top: 60px;
}

.loadingBIUtables{
    width: 100%;
    border: 5px solid #186b89;
    height: auto;
    margin: auto;
}

.buttondiv {
    padding: 10px;
}

.buttonadd:hover {
    transform: scale(1.025);
    background-color: #062a3d;
}

.editicons {
    color: #186b89;
}

.editicons:hover {
    color: #062a3d;
    border: 1px solid #062a3d;
}

.tabledata {
    border-bottom: 1px dotted #062a3d;
    text-align: center;
    height: 30px;
}

.tablerow {
    border-bottom: 1px dotted #062a3d;
}

.tablerowinvisible {
    display: none;
}

.savebiuicons {
    width: 150px;
    height: 30px;
    border: 1px solid green;
    color: lightgreen;
    background: rgb(0, 100, 0);
}

.savebiuicons:hover {
    border-radius: 10px;
}

.tablediv {
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 2px solid #186b89;
}

.errormsg {
    width: 100%;
    height: 100%;
    color: red;
    text-align: center;
    padding-top: 80px;
}

.divwithtable {
    width: 100%;
    height: 80%;
    overflow-y: scroll;
}

.tableBIUedit {
    width: 100%;
    align-content: center;
    overflow-y: scroll;
}

.tableHead {
    color: whitesmoke;
    background-color: #186b89;
    height: 60px;
}

.tablebody {
    overflow-y: scroll;
}

.tableDivHeaderBIU {
    background-color: #186b89;
    text-align: center;
    color: white;
    width: 100%;
    height: 30px;
}

.Biutablecontainer {
    overflow-y: scroll;
    padding: 20px;
    height: auto;
}

.addBiuicontables {
    font-size: x-large;
}

.EditModalcssbiu {
    padding-bottom:-80px;
    padding-top: 80px;
    height: 100%;
    padding-left: 60px;
    padding-right: 60px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 1;
    overflow-y: scroll;
    transition: 0.5s;
}

.EditModalcssbiuHidden {
    display: none;
}

.closeIconDivBIU {
    padding-left: 50px;
}

.closeIconBiu {
    color: white;
    background-color: red;
}

.BiuConfirmButton{
    background-color: red;
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin: auto;
    cursor: pointer;
    height: 30px;
    width : 20%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.BiuConfirmedButton{
    background-color: green;
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin: auto;
    cursor: pointer;
    height: 30px;
    width : 20%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.ConfirmBiuicon{
    color: white;
}