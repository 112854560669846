#mainPage {
    margin: 65px 10px 10px 270px;
  }
  
  .page {
    font-size: 9pt;
    padding:5px;
  }
  
  .invoiceHeader {
    font-size: 14pt;
    font-weight: normal;
    color: #585D61;
  }
  
  .inputCriteria {
    border: 1px solid #b0d2a3;
    background-color: #258db3;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    font-weight: bold;
    padding-left: 20px;
    margin-bottom: 15px;
    border-radius: 3px;
  }
  
  .selectionBoxRow {
    padding: 2px 0;
  }
  .selectionLabel {
    display: inline-block;
    width: 130px;
  }
  
  .selectionBoxSelect {
    width: 394px;
    padding: 3px;
  }
  
  
  
  .sectionContainer {
    display: block;
    margin-bottom: 20px;
    border: 1px solid #cacaca;
    padding: 10px;
    border-radius: 3px;
  }
  
  .cent {
    font-size: 14pt;
    font-weight: normal;
    color: #585D61;
  }
  
  .floatTheadWrapper table{
    border-collapse: collapse;
  }
  
  .floatTheadWrapper table{
  
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: gray;
  
  }
  
  
  
  .floatTheadWrapper tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    
  }
  
  .floatTheadWrapper tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    
  }
  
  .floatTheadWrapper th {
    text-align: left;
    color: #39547d;
    padding: 3px 0 3px 1px;
    font-size: 13px;
    font-weight: bold;
    border-collapse: collapse;
    border-bottom: 1px solid #cad5e5;
    min-height: 28px;
  }
  
  .floatTheadWrapper td, .floatTheadWrapper th {
    padding: 18px;
    border: 1px solid #c0c0c0;
    /* border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0; */
  
  }
  
  element.style {
    border-collapse: collapse;
    border: 0px none rgb(128, 128, 128);
    display: table;
    margin: 0px;
    table-layout: auto;
    width: 1185px;
  }
  
  .sectionTable {
    border-collapse: collapse;
    margin-bottom: 40px;
  }
  
  .thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    background-color: #EEEEEE;
  }
  
  .tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  
  .td{
    padding-right: 1px;
    padding-left: 1px;
    height: 28px;
    white-space: nowrap;
  }
  
  .tftr {
    padding: 5px;
    /* background-color: #176987; */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  
  /* .tbody{
    height: 30px;
    overflow-y: scroll;
  } */
  
  .contentDiv {
    /* margin-bottom: 8px; */
    margin:8px;
  
    padding:4px;
    /* width: 500px; */
    height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align:justify;
  }
  
  .submitBtn{
    background-color: #0d475c;
    border: none;
    color: #fff;
    border-radius: 5px;
    transition: .3s ease;
    padding: 12px 25px;
  
  }