.about-fincheck-section {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6em;
}

.about-fincheck-section__header {
    font-size: 2rem;
    margin-bottom: 1em;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
}

.about-fincheck-info__para {
    font-size: .9rem;
    line-height: 27px;
    font-family: 'Roboto', sans-serif;
}

.about-fincheck-illustration-container {
    display: none;
}

@media (min-width: 1028px){

    .about-fincheck-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: .3fr 1fr;
        grid-template-areas:
        "header header"
        "info illustration";
    }

    .about-fincheck-info-container {
        grid-area: info;
    }

    .about-fincheck-illustration {
        grid-area: illustration
    }

    .about-fincheck-illustration-container {
        display: block;
    }

    .about-fincheck-illustration-container > img {
        width: 70%;
        position: relative;
        left: 2em;
        top: -3em;
    }

    .about-fincheck-section__header{
        grid-area: header
    }

    .about-fincheck-info {
        width: 400px;
    }

    .about-fincheck-info__para {
        margin-top: 3em;
    }


}

@media (min-width: 1200px){

    .about-fincheck-info {
        width: 450px;
    }

    .about-fincheck-illustration-container > img {
        width: 80%;
        left: 2em;
    }

    .about-fincheck-info__para {
        line-height: 35px;
        position: relative;
        left: 2em;
    }
}