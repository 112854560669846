.login{
    overflow: hidden;
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("../backgrounds/login.jpg");
    background-size: 100% 100%;
    height: 100vh;
    font-family: "Roboto", sans-serif;
}
.login_details{
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    width: 30%;
    background: linear-gradient(rgba(0,0,0.3),rgba(0,0,0,0.3));
    padding: 3em 1.5em;
}
.login_input{
    border: none;
    width: 100%;
    outline-width: 0;
}
.username_password_container{
    width: 100%;
}
.lbl_txt_container{
    padding-bottom: 5px;
    width: 100%;
}
.div_label{
    text-align: left;
    color: antiquewhite;
    width: 25%;
    margin-top: auto;
    margin-bottom: auto;
    height: 20px;
}
.login_input_container{
    width : 100%;
    padding: .4em .5em; 
    border: 1px solid #186b89;
    border-radius: 5px;
    background-color: white;
}

.field_container {
    display: block;
    margin-bottom: 1.5em;
    width:100%
}
.td_input{
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: antiquewhite;
}
.td_label{
    padding-left: 5px;
    padding-right: 5px;
    height: 20px;
}
.top_header{
    width:100%;
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    background-color: #186b89;
    height: 30px;
    align-items: center;
    padding: 2em 1.5em;
    border-radius: 5px;
}

.top_header > h1 {
    color: white;
    font-size: 1.3rem;
}
.btn_login{
    width: 100%;
    background-color: var(--main-color);
    color: white;
    padding: 15px 0;
    border: none;
    border-radius: 5px;
}

.btn_forgot-password {
    background-color: transparent;
    border: none;
    color: white;
    margin-top: 1.5em;
}

.btn_forgot-password:hover {
    background-color: transparent;
    transform: scale(1.050);
}
.btn_login:hover{
    transform: scale(1.010);
    background-color: var(--main-color);
}
.tbl_entry{
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-bottom: auto;
}
input{
    width:100%;
}
.table_header{
    text-align: center;
}

.details_header > h2 {
    margin-left: auto;
    margin-right: auto;
}

.details_header{
    width: 100%;
    margin-top: 0%;
    background-color: #186b89;
    height: 0.8px;
    align-items: center;
    color: white;
    text-align: center;
}
/* h3{
    color: white;
    margin-right: 10%;
} */
.table_header > h4{
    margin-left: auto;
    margin-right: auto;
}
.top_header > h1{
    color: white
}
.homeicons:hover{
    color: black;
    font-size: 40px;
}
.homeicons{
    margin-right: 10%;
    font-size: 1.3rem ;
    color: white;
    border: none;
    text-decoration: none;

}

.home_link {
    text-decoration: none;
}
.lbl_login{
    width: 100%;
    color: white

}
.img_logo{
    height: 15px;
    width : 15px;
}
