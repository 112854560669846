.biu-report-header {
    width: 100%;
}

.fincheck-logo-container {
    width: 200px;
    margin-right: 15px;
}

.fincheck-logo-container > img {
    width: 100%;
}

.biu_logo_header {
    display: flex;
    align-items: center;
}

.fin-motto {
    font-size: .4rem;
    text-transform: capitalize;
    width: 100%;
}

.biu-intro-header {
    display: flex;
    justify-content: center;
}

.biu-intro-header > h2 {
    text-align: center;
    margin: 1em auto;
    width: 100%;
}



.biu-intro-info  {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    margin: 1em;
    padding: 10px 0;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.rating-container,
.client-name-container {
    justify-self: start;
    display: flex;
    font-size: .9rem;
    font-weight: 500;
}


.rating-container > p:nth-child(1),
.client-name-container > p:nth-child(1){
    margin-right: 15px;
}

.client-name-container {
    margin-left: 30%;
}

.date-of-biu-report {
    padding: 10px 0;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
}

.report-date {
    display: flex;
    justify-content: center;
    font-size: .9rem;
    padding: 10px 0;
}

.report-date > p:nth-child(1){
    margin-right: 15px;
}



