.mainContainer {
    width: 100%;
    background-color: whitesmoke;
    border: 3px solid #062a3d;
    height: auto;
   /*  position: fixed; */
}

.BulkTableData{
    text-align: center;
}

.nodataptag{
    color: red;
    align-self: center;
}

.uploader{
    border :  2px solid #062a3d;
    width : 50%;
    height: 100%;
    padding : 20px
}
.UploadSection{
    padding: 10px;
}

.UploadSecMain{
    padding: 10px;
}

.UploadSecMainButons{
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.previewSection{
    height: 400px;
    width : auto;
    padding: 10px;
    border: 2px solid #186b89;
    overflow-y: scroll;
}
.prevTableBody{
    height: 400px;
}

.uploaderInput{
    height: auto;
    width : auto;
    border: 2px solid#186b89;
}

.buttonDiv{
    height: auto;
    width : auto;
    border: 2px solid#186b89;
    justify-content: space-around;
    padding: 10px;
    display: flex;
}

.UploadButton{
    background-color: #186b89;
    height: 40px;
    text-align: center;
    padding: 10px;
    color:white;
    border:#186b89;
    display: flex;
    justify-content: center;
}

.UploadButtondone{
    background-color: green;
    height: 40px;
    text-align: center;
    padding: 10px;
    color:white;
    border:#186b89;
    display: flex;
    justify-content: center;
}

.UploadButtonerror{
    background-color: red;
    height: 40px;
    text-align: center;
    padding: 10px;
    color:white;
    border:#186b89;
    display: flex;
    justify-content: center;
}

.previewrows{
    width: 100%;
}

.previewTable{    
    width: 100%;
    background-color: whitesmoke;
}

.tablecontainerbulk{
    width: 100%;
    padding-top: 0px;
    height: 100%;
    overflow-y: scroll;
}

.InputForm{
    display: flex ;
    width : 100%;
    height: auto;
    border: 1px solid #186b89;
}
.buttonSection{
    height: 100px;
    width : auto;
    padding: 10px;
}

.tableheader{
    background-color: #186b89;
    color: whitesmoke;
    width: 100%;
    height: 30px;
    text-align: center;
    position: sticky;
    top: 0px;
}

.header{
    background-color: #186b89;
    text-align: center;
    color : whitesmoke;
}

.fileuploder{
    height: auto;
    width : auto;
}

.infosec{
    width: 100;
    padding: 10px;
    height: 100%;
    justify-content: center;
}

.info{
    justify-content:center;
    margin: auto;
    height: 20%;
    display: flex;
}

.file{  
    display: flex;  
    justify-content: space-between;
    margin: auto auto auto auto;
    padding-top: 10px;
    height: 30%;
}

.semicolony{
    padding-left: 10px;
    padding-right: 10px;
}

.corrupt{
    color: red;
}

.dataentrypanel{
    width: 100%;
    height:auto;
    border: 2px solid #186b89;
    padding: 10px;
}

.inputdivisionbulknegetive{
    width: 100%;
    display: flex;
}