.input-container {
    width: 30%;
    margin-bottom: 2em;
    margin-left: 2.5%;
    height: 70px;

}

.value-container-file{
    padding: .5em;
    border: 1px solid gray;
    border-radius: 5px;
    width: 100%;
    height: 50%;
}

.input-area-container{
    width: 30%;
    margin-bottom: 2em;
    margin-left: 2.5%;
    height: 80px;
}

.input-container > label {
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    display: block;
    margin-bottom: 1em;
    font-size: .9rem;
    font-weight: 500;
}

.slt_temp{
    width : 100%;
    height : 100%
}
.opt_temp{
    width : 100%;
    height : 100%
}

.value-container {
    padding: .5em;
    border: 1px solid gray;
    border-radius: 5px;
    width: 100%;
    height: 50%;
}

.value-container-table {
    border-radius: 5px;
    width: 100%;
    height: 20px;
}

.inputtablecss{
    border: 1px solid gray;
    padding: 0.5em;
    margin: auto;
    height: 100%;
    width: 100%;
}

.value-container > input {
    width: 100%;
    height: 100%;
    border: none;
    outline-width: 0;
    background-color: transparent;
}

.valuearea-container {
    padding: .5em;
    border: 1px solid gray;
    border-radius: 5px;
    height: 80px;
}

.valuearea-container > textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline-width: 0;
    background-color: transparent;
}

.searched-dropdown {
    height: 420px;
    overflow-y: scroll;
    z-index: 100;
    width: 100%;
    position: relative;
    background-color: var(--main-color);

}

.searched-dropdown > ul li {
    color: #fff;
    font-size: .9rem;
    padding: 10px;
    border-bottom: 1px solid gray;
    cursor: pointer;
}

.searched-dropdown > ul li:hover {
    background-color: white;
    color: var(--main-color);
}

.fileuploadercontainer-div{
    border: 2px solid #186b89;

}

.label-required{
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    display: flex !important;
    margin-bottom: .5em !important ;
    font-size: .9rem;
    font-weight: 500;
}

.required-asteric{
    color: red;
}

