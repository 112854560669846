.admin-view-container {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 15% 85%;
    grid-template-rows: 13% 87%;
    grid-template-areas: 
    "logo-section top-navbar"
    "side-navbar view-section";
    overflow: hidden;
    transition: .3s ease;
}

.logo-section {
    grid-area: logo-section;
}

.navbar-section {
    grid-area: side-navbar;
    background-color: var(--main-color);
}

.top-navbar-section {
    grid-area: top-navbar;
    background-color: whitesmoke;
}

.view-section {
    grid-area: view-section;
}

.toggle-sidebar {
    grid-template-columns: 3% 97%;
}

.hide-logo {
    display: none;
}

.center-burger {
    position: relative;
    left: 30%;
}