.divaddcomp {
    width: 80vw
}

.txtPhonenumber {
    display: flex
}

.frm_divinput {
    border: 0.5px solid skyblue;
    width: 80%;
    height: 30px;
}

.executive-form-searchDiv {
    width: 50%;
}

.frmaddcompany_divfinancialdistrict {
    border: 0.5px solid blue;
    padding-top: 50px;
    display: flex;

}

.frmaddcompany_divcompanydetails {
    border: 0.5px solid brown;
    padding-top: 50px;
    display: flex;
}

.frmAddcompanies_divbtn {
    border: 0.5px solid red;
    display: flex;
    padding-top: 50px;
}

.frmaddcompany_divphone {
    border: 0.5px solid green;
    padding-top: 50px;
    display: flex
}

.frmaddcompany_divAddress {
    border: 0.5px solid yellow;
    padding-top: 50px;
    display: flex
}

.frmaddcompany_divlegalStatus {
    border: 0.5px solid orange;
    padding-top: 50px;
    display: flex
}

.frmaddcompany_divtradingStatus {
    border: 0.5px solid brown;
    padding-top: 50px;
    display: flex
}

.frmaddcompany_divonTheWeb {
    border: 0.5px solid violet;
    padding-top: 50px;
    display: flex
}

.secinputcontainer {
    margin: auto;
    width: 98%;
    border: 2px solid #186b89;
}

.secButtons {
    margin: auto;
    padding: 1.5em .7em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
    border: 2px solid #186b89;
    width: 98%;
}

.secinputdeatails {
    padding: 1.5em .7em;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
}

.slt_temp {
    width: 100%;
    height: 100;
    border: none;
}


.sectionHeading {
    background-color: var(--main-color);
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
    width: 100%;
}

.sectionHeadinghpifinancier {
    background-color: red;
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
    width: 100%;
}

.sectionHeadinghpilessee {
    background-color: var(--main-color);
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
    width: 100%;
}

.sectionHeadingdetailshpi{
    background-color: black;
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
    width: 100%;
}