.top-navbar {
    width: 90%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-nav {
    flex: .4;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-nav__btns-container {
    flex: .3;
    display: flex;
    justify-content: flex-end;
}

.top-nav > a,
.top-nav > button {
    text-transform: capitalize;
    margin-left: 1.5em;
    font-family: 'Roboto', sans-serif;
}

.top-nav > a {
    color: var(--main-color);
    text-decoration: underline;
} 

.logout-btn,
.notifications {
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color:red;
    color: white;
    border: none;
    padding: 10px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: .3s ease;
}

.notifications {
    background-color: transparent;
    color: var(--main-color);
    margin-right: .7em;
}

.notifications > span {
    background-color: var(--main-color);
    color: white;
    padding: 5px 7px;
    border-radius: 5px;
}

.logout-btn:hover {
    border-radius: 30px;
}

.top-nav > a:hover {
    font-weight: 550;
}

.user-details {
    flex: .3;
}

.username,
.system-level{
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
}

.system-level {
    font-size: .9rem;
    margin-top: 5px;
    font-weight: 500;
}


