.our-values-container {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
}

.our-values-header {
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;
    margin-bottom: 1em;
}

.values {
   color: var(--main-color);
}

.values > li {
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    margin-bottom: .9em;
    position: relative;
    left: 2em;
}

.values > li span {
    color: black;
}

@media (min-width: 1104px){

    .our-values-container {
        position: relative;
        margin-top: 1em;
    }

    .values {
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style: none;
        position:  relative;
        top: 1em;
    }

    .values li {
        padding: 25px 25px;
        background-color: var(--main-color);
        color: white;
        border-radius: 5px;
        position: relative;
        left: 0;
    }

    .values li span {
        color: white;
    }
}

@media (min-width: 1200px){

    .our-values-container {
        margin-top: -3em;
    }
}