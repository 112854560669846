
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    margin-right: auto;
    margin-left: auto;
    padding: 1.5em 0;
    position: sticky;
    
}

.logo {
    width: 150px;
}

.homepage-nav {
    position: absolute;
    right: -1.2em;
    width: 150px;
    background-color: var(--main-color);
    height: 100vh;
    border-radius: 5px;
    top: 4em;
    transition: .5s ease;
    padding: 0em;
    overflow: none;
    display: none;
}

.show-homepage-nav {
    padding: 1.5em;
    display: block;
}

.homepage-nav a {
    display: flex;
    align-items: center;
    color:  #fff;
    padding: 15px 0;
    margin-bottom: 1.5em;
    text-decoration: none;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
}

.burger{
    background-color: var(--main-color);
    color: white;
    border: none;
    padding: 5px;
    border-radius: 5px;
}

.navIcon {
    color: white;
    margin-right: 10px;
}

@media (min-width: 775px){

    header {
        height: 12vh;
    }

    .logo-container {
        flex: .3;
    }

    .homepage-nav {
        flex: .7;
        position: initial;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: auto;
        padding: 0;
        right: 0;
        width: auto;
    }

    .homepage-nav a {
        padding: 0;
        margin: 0;
        color: black;
        font-size: .9rem;
        transition: .3s ease;
    }

    .homepage-nav a:hover {
        background-color: var(--main-color);
        color: #fff;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .burger-container {
        display: none;
    }

    .navIcon {
        color: var(--main-color);
        margin-right: 5px;
    }

    .login-link{
        background-color: var(--main-color);
        padding: 10px 15px !important;
        color: white !important;
        border-radius: 5px;
        padding: 15px 25px;
        margin: 30em;
    }

    .login-link:hover {
        transform: scale(1.1);
    }

    .login-link > .navIcon {
        color: white;
    }
}

@media (min-width: 1200px){

    header {
        margin-top: 1em;
    }
}
