.modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.8);
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow-y: scroll;
    padding: 1.5em;
    display: grid;
    place-items: center;
    transition: .3s ease;
}

.close-modal-icon {
    color: white;
    position: absolute;
    top:1em;
    right: 1.5em;
    cursor: pointer;
    background-color: red;
    border-radius: 5px;
}

.close-modal-icon:hover {
    transform: scale(1.025);
    color: red;
    background-color: white
}


.loader-container {
    background-color: white;
    padding: 1.5em;
    height: 200px;
    width: 350px;
    border-radius: 5px;
}

.close-claim-icon {
    background-color: red;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.close-claim-icon:hover {
    background-color: white;
    color: red;
}

.loader-container {
    display: grid;
    place-items: center;
}

.response-message {
    text-align: center;  
} 

.response-message  > p {
    font-size: .9rem;
    margin-top: 1em;
    text-transform: capitalize;
}