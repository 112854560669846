.logo-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.admin-logo-container > img {
    width: 120px;
    position: relative;
    left: .3em;
}

.admin-burger {
    background-color: var(--main-color);
    margin-right: .5em;
    color: white;
    padding: .1em;
    border-radius: 5px;
    cursor: pointer;
}

.admin-burger:hover {
   transform: scale(1.025); 
}

.hide-sidenav {
    display: none;
    width: 0%;
    height: 0;
    overflow: hidden;
    
}