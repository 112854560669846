.company-report-logo-container {
    height: 200px;
    width: 300px;
    text-align: left;
}

.company-report-logo-container > img {
    width: 100%;
}

.company-report-header-row{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.company-report{
    border: 5px solid black;
    width : 90%;
    margin: auto auto auto auto;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.full-width {
    width: "100%";
}

.report-header {
    width: 100%;
    display: block;
}

.header-address{
    text-align: left;
    padding-top: 10px;
    margin-left: 30%;
}

.header-address,
.header-contactinfo {
    margin-top: 1em;
}


.txtheader-address,
.moto,
.txtheader-contact {
    font-size: .9rem;
    font-weight: 500;
    text-align: left;
}


.header_contactinfo{
    text-align: left;
    padding-top: 10px;
    border-left: 1px solid black;
}