.reviews-section {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
}

.reviews-section__header {
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;

}

.reviews-container {
    margin-top: 2em;
}

.review {
    padding: 1.5em;
    background-color: #f6f6f6;
    margin-bottom: 1.5em;
    border-radius: 5px;
 
}

.review-header {
    display: flex;
    align-items: center;
}

.review-img-container,
.white-circle{
    display: flex;
    align-items: center;
    width: 70px;
    height: 70px;
    background-color: white;
    border-radius: 50%;
}

.review-img-container > img {
    width: 100%;
}

.reviewer-details {
    margin-left: 10px;
}

.reviewer-details > p {
    font-size: .9rem;
    font-family: 'Roboto', sans-serif;
}


.reviewer-name {
    font-weight: 600;
    margin-bottom: 5px;
}

.review_para {
    margin-top: 1em;
    line-height: 25px;
    font-size: .9rem;
    font-family: 'Roboto', sans-serif;
}

@media (min-width:  450px){

    .review {
        width: 339px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width:  800px){

    .reviews-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
}