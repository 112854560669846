.clientsMainDiv {
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
    padding-bottom: 20px;
}

.formButtonsClients {
    background-color: var(--main-color);
    color: white;
    display: flex;
    border-radius: 5px;
    border: none;
    margin: auto;
    cursor: pointer;
    height: 30px;
    width : 100%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.clientloadingcircle{
    width: 100%;
    padding-left: 49%;
}

.tablecontainerclientstablescroll{
    overflow-y: scroll;
    height : 90%;
}

.clientSearchbuttontableclient{
    width: 80px;
    height: 30px;
    background-color: #186b89;
    color: white;
}

.inputsearchclientstable{
    width: 30%;
    height: 30px;
}

.searchpanel{
    height: 8%;
    border-bottom: 2px solid #186b89;
}

.clientsarenaheader {
    width: 100%;
    height: 6%;
    background-color: #186b89;
    color: white;
    padding-left: 45%;
    padding-top: 2px;
}

.clientsTableContainer {
    padding: 10px;
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
}

.clientsTable {
    width: 100%;
    background-color: white;
}

.clientstableheader {
    background-color: #186b89;
    color: white;
    height: 30px;
    position: sticky;
    top: 0;
}

.clientstableloading {
    margin: auto;
}

.editiconsclients {
    color: #186b89;
}


.editiconsclients:hover {
    color: #062a3d;
    border: 1px solid #062a3d;
}

.clientstabledata {
    border-bottom: 1px dotted #062a3d;
    text-align: center;
}

.clientstabledataicons {
    border: 1px dotted #062a3d;
    text-align: center;
}

.activeclient:hover {
    border: 1px solid green;
    background-color: white;
}

.suspendedclient:hover {
    border: 1px solid red;
    background-color: white;
}


.clientstabledata-active {
    border: 1px dotted #062a3d;
    text-align: center;
    color: green;
}

.clientstabledata-suspended {
    border: 1px dotted #062a3d;
    text-align: center;
    color: red;
}

.clientstableheaderadd {
    background-color: white;
}

.editiconsclientsdelete {
    color: red;
}

.editiconsclientsdelete:hover {
    color: red;
    border: 1px solid #062a3d;
}

.clientaddheader {
    color: green;
}


.addclientmain {
    padding: 40px;
    display: flex;
    width: 100%;
    height: 100%;
}

.addclientnavigation {
    width: 20%;
    height: 100%;
    border: 2px solid #062a3d;
}

.addclientforms {
    width: 80%;
    height: 100%;
    border: 2px solid #186b89;
    overflow-y: scroll;
    padding: 20px;
    background-color: whitesmoke;
}

.navigationaddclient {
    background-color: #186b89;
    width: 100%;
    height: 100%;
}

.addeditnavbutton {
    color: white;
    height: 30px;
    width: 100%;
    background-color: #186b89;
    border: 1px solid white;
}

.addeditnavbutton:hover {
    color: #062a3d;
    height: 30px;
    width: 100%;
    background-color: white;
    border: 1px solid white;
}

.addeditnavbuttonactive {
    color: #062a3d;
    height: 30px;
    width: 100%;
    background-color: whitesmoke;
    border: 1px solid white;
}

.circularprogressdivclientstanle {
    width: 100%;
    padding-left: 48%;
}

.newuserclient {
    width: 100%;
    height: auto;
    border: 2px solid #062a3d;
    background-color: whitesmoke;
}

.newuserHeader {
    width: 100%;
    height: 40px;
    color: white;
    background-color: #186b89;
    padding-left: 42%;
}

.adduserclientheader {
    margin: auto;
}

.userDetails {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding: 10px;
}

.adduserheadingtext {
    height: auto;
    width: auto;
    margin: auto;
    background-color: #186b89;
}

.detailsuser {
    display: flex;
    width: 100%;
    height: auto;
}

.secButtonsclients {
    margin: auto;
    padding: 1.5em .7em;
    border-top: 2px solid #186b89;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
    width: 98%;
}

.addClientmodalContainer {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 1;
}

.addClientmodalContainerhide {
    visibility: hidden;
}

.closeIcon {
    color: white;
    background-color: red;
}

.closeIcon:hover {
    color: red;
    background-color: white;
}

.editClientContainerModal {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 1;
}

.editClientContainerModalHide {
    visibility: hidden;
}

.editclientmain {
    padding: 40px;
    display: flex;
    width: 100%;
    height: 100%;
}

.loadingcircleclients {
    margin: auto;
    width: 100%;
    padding-left: 48%;
}

.loadingstatusclient {
    margin: auto;
}